.background {
    position: relative;
    width: 100%;
    /* background-image: url('../../../../public/assets/Image/fun-roulette/fun-bg.png'); */
    height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
}

.background .main-banner-img {
    width: 100%;
}

.background .subbackground {
    position: absolute;
    top: 0;
    left: 0;
}

.background .subbackground .banner-img {
    width: 100%;
}

.background .dimond-img-main {
    position: absolute;
    top: 2%;
    left: 48%;
}

.background .dimond-img-main .diamond-img {
    height: 88px;
    width: 88px;
}

.background .dimond-img-main {
    position: absolute;
    top: 2%;
    left: 48%;
}

.background .dimond-img-small {
    position: absolute;
    top: 7%;
    left: 4%;
}

.background .dimond-img-small .diamond-left-top {
    height: 34px;
    width: 34px;
}

.background .dimond-left-bottom {
    position: absolute;
    bottom: 7%;
    left: 4%;
}

.background .dimond-left-bottom .diamond-left-top {
    height: 34px;
    width: 34px;
}

.background .dimond-right-top {
    position: absolute;
    top: 7%;
    right: 4%;
}

.background .dimond-right-top .diamond-left-top {
    height: 34px;
    width: 34px;
}

.background .dimond-right-bottom {
    position: absolute;
    bottom: 7%;
    right: 4%;
}

.background .dimond-right-bottom .diamond-left-top {
    height: 34px;
    width: 34px;
}

/* 22-12 */
.background .bg-glow {
    height: 30px;
    width: 201px;
    position: absolute;
    top: -62%;
    right: -66%;
}

.background .left-top-bg-glow {
    height: 30px;
    width: 120px;
    position: absolute;
    top: -40px;
    left: -112%;
}

@media(min-width:577px) and (max-width:768px) {
    .background .dimond-img-small .diamond-left-top {
        height: 13px;
        width: 13px;
    }

    .background .dimond-img-small {
        top: 6%;
    }

    .background .dimond-right-top .diamond-left-top {
        height: 13px;
        width: 13px;
    }

    .background .dimond-right-top {
        top: 6%;
    }

    .background .dimond-left-bottom .diamond-left-top {
        height: 13px;
        width: 13px;
    }

    .background .dimond-left-bottom {
        bottom: 6%;
    }

    .background .dimond-right-bottom .diamond-left-top {
        height: 13px;
        width: 13px;
    }

    .background .dimond-right-bottom {
        bottom: 6%;
    }

    .background .dimond-img-main .diamond-img {
        height: 38px;
        width: 38px;
    }

    .background .left-top-bg-glow {
        width: 47px;
        top: -7px;
        left: -112%;
    }

    .background .bg-glow {
        width: 87px;
        top: -62%;
        right: -66%;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .background .dimond-img-small .diamond-left-top {
        height: 11px;
        width: 13px;
    }
    
    .background .dimond-img-small {
        top: 6%;
    }

    .background .dimond-right-top .diamond-left-top {
        height: 13px;
        width: 13px;
    }

    .background .dimond-right-top {
        top: 6%;
    }


    .background .dimond-left-bottom .diamond-left-top {
        height: 13px;
        width: 13px;
    }

    .background .dimond-left-bottom {
        bottom: 6%;
    }

    .background .dimond-right-bottom .diamond-left-top {
        height: 13px;
        width: 13px;
    }

    .background .dimond-right-bottom {
        bottom: 6%;
    }

    .background .dimond-img-main .diamond-img {
        height: 30px;
        width: 30px;
    }
    .background .left-top-bg-glow {
        width: 47px;
        top: -7px;
        left: -112%;
    }

    .background .bg-glow {
        width: 87px;
        top: -62%;
        right: -66%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .background .dimond-img-small {
        top: 6%;
    }

    .background .dimond-img-small .diamond-left-top {
        height: 18px;
        width: 18px;
    }

    .background .dimond-right-top {
        top: 6%;
    }

    .background .dimond-right-top .diamond-left-top {
        height: 18px;
        width: 18px;
    }

    .background .dimond-left-bottom .diamond-left-top {
        height: 18px;
        width: 18px;
    }

    .background .dimond-left-bottom {
        bottom: 6%;
    }

    .background .dimond-right-bottom .diamond-left-top {
        height: 18px;
        width: 18px;
    }

    .background .dimond-right-bottom {
        bottom: 6%;
    }

    .background .dimond-img-main .diamond-img {
        height: 45px;
        width: 45px;
    }

    .background .bg-glow {
        width: 110px;
        top: -64%;
    }

    .background .left-top-bg-glow {
        width: 68px;
        top: -19px;
        left: -124%;
    }
}

@media(min-width:1201px) and (max-width:1440px) {
    .background .dimond-img-main {
        top: 3%;
    }

    .background .dimond-img-main .diamond-img {
        height: 60px;
        width: 60px;
    }

    .background .dimond-img-small .diamond-left-top {
        height: 25px;
        width: 25px;
    }

    .background .dimond-right-top .diamond-left-top {
        height: 25px;
        width: 25px;
    }

    .background .dimond-left-bottom .diamond-left-top {
        height: 25px;
        width: 25px;
    }

    .background .dimond-right-bottom .diamond-left-top {
        height: 25px;
        width: 25px;
    }

    .login-form .welcome-text {
        font-size: 38px;
    }

    .text-holder-login .title-form {
        font-size: 19px;
    }

    .login-form form label {
        font-size: 16px;
    }
    .background .bg-glow {
        width: 147px;
        top: -67%;
        right: -66%;
    }
    .background .left-top-bg-glow {
        width: 97px;
        top: -30px;
    }
}