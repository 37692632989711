@font-face {
    font-family: Gamer Station Extrude;
    src: url('../../../../public/assets/Image/Gamer\ Station\ Extrude.ttf');
}

.registration-form {
    position: absolute;
    top: 12%;
    left: 7%;
    right: 7%;
}

.registration-form .register-bg {
    position: relative;
}

.registration-form .register-bg .register-form {
    position: absolute;
    top: 2%;
    left: 16%;
}

.registration-form .register-bg .register-form form label {
    font-size: 18px;
    color: #D2D235;
    margin-top: 8%;
    font-family: 'Gamer Station Extrude';
}

.registration-form .register-bg .register-form form input {
    background: transparent;
    border: none;
    width: 397px;
    height: 40px;
}

input[type=date]:invalid::-webkit-datetime-edit {
    color: #FFF;
}

.registration-form .register-bg .register-form .register-title h2 {
    font-size: 30px;
    color: #FFF;
    font-family: 'Gamer Station Extrude';
}


.registration-form .register-bg .register-sub .registerbg-img {
    width: 100%;
}

.registration-form .submit-btn {
    width: 103px;
    height: auto;
    background: #DF12EE;
    border: 2px solid #E50199;
    border-radius: 10px;
    color: #FFF;
    font-size: 24px;
    font-family: 'Gamer Station Extrude';
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    font-size: 12px;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.registration-form .register-form .input-group {
    width: 100%;
    background-size: cover;
    height: 38px;
    background-image: url('/public/assets/Image/registration/input-bg.png');
}


.registration-form .register-form .input-group .form-control {
    color: #fff;
    font-family: 'Gamer Station Extrude';
}

/* Media query */

@media(min-width:577px) and (max-width:768px) {
    .registration-form .register-bg .register-form .register-title h2 {
        font-size: 12px;
    }

    .registration-form {
        top: 4%;
    }

    .registration-form .register-form .input-group {
        height: 15px;
    }

    .registration-form .register-bg .register-form form input {
        width: 100%;
        height: 16px;
    }

    .registration-form .register-form .input-group .form-control {
        font-size: 10px;
    }

    .registration-form .register-bg .register-form {
        top: 2%;
        left: 1%;
        padding: 0px 47px;
    }

    .registration-form .register-bg .register-form form label {
        font-size: 10px;
        margin-top: 0;
    }

    .registration-form .submit-btn {
        width: fit-content;
        height: 30px;
        font-size: 10px;
        padding: 2px 12px;
    }

    .background .subbackground .banner-img {
        height: 436px;
    }

    .registration-form .register-bg .register-sub .registerbg-img {
        height: 278px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .registration-form .register-bg .register-form .register-title h2 {
        font-size: 15px;
    }

    .registration-form {
        top: 4%;
    }

    .registration-form .register-bg .register-form form input {
        width: 100%;
        height: 16px;
    }

    .registration-form .register-form .input-group .form-control {
        font-size: 10px;
    }

    .registration-form .register-form .input-group {
        height: 18px;
    }

    .registration-form .register-bg .register-form {
        top: 2%;
        left: 16%;
    }

    .registration-form .register-bg .register-form form label {
        font-size: 10px;
        margin-top: 0;
    }

    .registration-form .submit-btn {
        width: 73px;
        height: auto;
        font-size: 12px;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .registration-form .register-bg .register-form .register-title h2 {
        font-size: 15px;
    }

    .registration-form {
        top: 3%;
        left: 5%;
        right: 9%;
    }
    
    .registration-form .register-bg .register-form form input {
        width: 100%;
        height: 29px;
    }

    .registration-form .register-bg .register-form {
        top: 2%;
        left: 6%;
    }

    .registration-form .register-bg .register-form form label {
        font-size: 10px;
        margin-top: 0;
    }

    .registration-form .submit-btn {
        width: 73px;
        height: auto;
        font-size: 12px;
    }

    .registration-form .register-form .input-group {
        height: 27px;
    }
    .registration-form .register-bg .register-sub .registerbg-img {
        width: 105%;
    }
}

@media(min-width:1201px) and (max-width:1440px) {
    .registration-form {
        position: absolute;
        top: 8%;
    }

    .registration-form .register-bg .register-form .register-title h2 {
        font-size: 22px;
    }

    .registration-form .register-bg .register-form {
        left: 11%;
        top: 1%;
    }

    .registration-form .register-form .input-group {
        height: 30px;
    }

    .registration-form .register-bg .register-form form label {
        font-size: 15px;
        margin-top: 19px;
    }

    .registration-form .submit-btn {
        width: 80px;
        height: auto;
        font-size: 16px;
    }

    .registration-form .register-bg .register-form form input {
        width: 300px;
        height: 30px;
    }
}

@media(min-width:1441px) and (max-width:2000px) {
    .registration-form {
        position: absolute;
        top: 8%;
    }

    .registration-form .register-bg .register-form .register-title h2 {
        font-size: 22px;
    }

    .registration-form .register-bg .register-form {
        left: 14%;
        top: 2%;
    }

    .registration-form .register-form .input-group {
        height: 30px;
    }

    .registration-form .register-bg .register-form form label {
        font-size: 15px;
        margin-top: 19px;
    }

    .registration-form .submit-btn {
        width: 80px;
        height: auto;
        font-size: 16px;
    }

    .registration-form .register-bg .register-form form input {
        width: 300px;
        height: 30px;
    }
}

@media(min-width:2201px) and (max-width:3000px) {
    .registration-form .register-bg .register-form {
        top: 2%;
        left: 22%;
    }

    .background .dimond-img-main {
        top: 3%;
    }

    .registration-form {
        top: 16%;
    }

    .registration-form .register-bg .register-form form label {
        font-size: 20px;
        margin-top: 11%;
    }
}