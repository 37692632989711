@font-face {
    font-family: Gamer Station Extrude;
    src: url('../../../../public/assets/Image/Gamer\ Station\ Extrude.ttf');
}

.Check-authonticity .title-check h5 {
    font-size: 28px;
    font-weight: 600;
    color: #FFF;
    text-decoration: underline;
    font-family: 'Gamer Station Extrude';
}

.Check-authonticity .sub-title-check {
    position: relative;
}

.Check-authonticity .sub-title-check .text-holder-receivable {
    position: absolute;
    top: 0;
    left: 17%;
}

.Check-authonticity .sub-title-check .text-holder-receivable p {
    font-size: 21px;
    color: #FFF;
    font-weight: 600;
    font-family: 'Gamer Station Extrude';
}

.Check-authonticity .sub-title-check .text-holder-receiv {
    position: absolute;
    top: 21%;
    bottom: 0;
    left: 45%;
}

.Check-authonticity .sub-title-check .text-holder-receiv p {
    font-size: 21px;
    color: #FFF;
    font-weight: 600;
    font-family: 'Gamer Station Extrude';
}

.Check-authonticity .sub-title-check .text-holder-reject {
    position: absolute;
    top: 21%;
    bottom: 0;
    left: 73%;
}

.Check-authonticity .sub-title-check .text-holder-reject p {
    font-size: 21px;
    color: #FFF;
    font-weight: 600;
    font-family: 'Gamer Station Extrude';
}

/* .Check-authonticity .check-img-main:hover {
     box-shadow:  #FF00008C 3px 6px 10px;
     border-radius: 20px;
     opacity: 1;
 } */

.Check-authonticity .check-img-main .check-img {
    width: 100%;
}

.Check-authonticity .first-section {
    position: relative;
}

.Check-authonticity .first-section .text-holder-account-no {
    position: absolute;
    top: 21%;
    bottom: 0;
    left: 17%;
}

.Check-authonticity .first-section .text-holder-account-no p {
    font-size: 13px;
    color: #FFF;
    margin-top: 6px;
    font-family: 'Gamer Station Extrude';
}

.Check-authonticity .first-section .text-holder-amount {
    position: absolute;
    top: 21%;
    bottom: 0;
    left: 45%;
}

.Check-authonticity .first-section .text-holder-amount p {
    font-size: 13px;
    color: #FFF;
    margin-top: 6px;
    font-family: 'Gamer Station Extrude';
}

.Check-authonticity .first-section .text-holder-type {
    position: absolute;
    top: 21%;
    bottom: 0;
    left: 73%;
}

.Check-authonticity .first-section .text-holder-type p {
    font-size: 13px;
    color: #FFF;
    margin-top: 6px;
    font-family: 'Gamer Station Extrude';
}

.Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox {
    position: absolute;
    top: 20%;
    left: 31%;
}

.Check-authonticity .first-section .select-check-main {
    position: absolute;
    top: 83%;
    bottom: 0;
    left: 33%;
}

.Check-authonticity .first-section .select-check-main .select-all-main {
    position: relative;
}

.first-section .select-check-main .select-all-main .select-all-btn .select-img {
    width: 200px;
}


.Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox label {
    color: #FFF;
    font-family: 'Gamer Station Extrude';
}

.Check-authonticity .ok-btn-main {
    position: relative;
}

.Check-authonticity .ok-btn-main .ok-btn-img .ok-img {
    width: 103px;
    height: 31px;
}

.Check-authonticity .ok-btn-main .text-holder-ok {
    position: absolute;
    top: 0;
    left: 47%;
}

.Check-authonticity .ok-btn-main .text-holder-ok .ok-btn {
    background: transparent;
    border: none;
    font-size: 18px;
    color: #FFF;
    cursor: pointer;
    font-family: 'Gamer Station Extrude';
}

.Check-authonticity .response-text {
    font-size: 28px;
    font-weight: 600;
    color: #FFF;
    text-align: start;
    font-family: 'Gamer Station Extrude';
}

@media(min-width:577px) and (max-width:768px) {
    .Check-authonticity .ok-btn-main .ok-btn-img .ok-img {
        width: 56px;
        height: 24px;
    }

    .Check-authonticity .title-check h5 {
        font-size: 12px;
    }

    .Check-authonticity .sub-title-check .text-holder-receivable p {
        font-size: 7px;
    }

    .Check-authonticity .sub-title-check .text-holder-receiv p {
        font-size: 7px;
    }

    .Check-authonticity .sub-title-check .text-holder-reject p {
        font-size: 7px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox label {
        font-size: 9px;
    }

    .Check-authonticity .first-section .text-holder-account-no p {
        font-size: 5px;
    }

    .Check-authonticity .first-section .text-holder-amount p {
        font-size: 5px;
    }

    .Check-authonticity .first-section .text-holder-type p {
        font-size: 5px;
    }

    .first-section .select-check-main .select-all-main .select-all-btn .select-img {
        width: 109px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox {
        top: 0;
        left: 15%;
    }

    .Check-authonticity .ok-btn-main .text-holder-ok .ok-btn {
        font-size: 16px;
    }

    .Check-authonticity .response-text {
        font-size: 12px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .Check-authonticity .ok-btn-main .ok-btn-img .ok-img {
        width: 56px;
        height: 24px;
    }

    .Check-authonticity .title-check h5 {
        font-size: 12px;
    }

    .Check-authonticity .sub-title-check .text-holder-receivable p {
        font-size: 7px;
    }

    .Check-authonticity .sub-title-check .text-holder-receiv p {
        font-size: 7px;
    }

    .Check-authonticity .sub-title-check .text-holder-reject p {
        font-size: 7px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox label {
        font-size: 9px;
    }

    .Check-authonticity .first-section .text-holder-account-no p {
        font-size: 5px;
    }

    .Check-authonticity .first-section .text-holder-amount p {
        font-size: 5px;
    }

    .Check-authonticity .first-section .text-holder-type p {
        font-size: 5px;
    }

    .first-section .select-check-main .select-all-main .select-all-btn .select-img {
        width: 109px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox {
        top: 0;
        left: 15%;
    }

    .Check-authonticity .ok-btn-main .text-holder-ok .ok-btn {
        font-size: 16px;
    }

    .Check-authonticity .response-text {
        font-size: 12px;
    }
}

@media(min-width:992px) and (max-width:1200px) {

    .Check-authonticity .ok-btn-main .ok-btn-img .ok-img {
        width: 56px;
        height: 24px;
    }

    .Check-authonticity .title-check h5 {
        font-size: 15px;
    }

    .Check-authonticity .sub-title-check .text-holder-receivable p {
        font-size: 10px;
    }

    .Check-authonticity .sub-title-check .text-holder-receiv p {
        font-size: 10px;
    }

    .Check-authonticity .sub-title-check .text-holder-reject p {
        font-size: 10px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox label {
        color: #FFF;
        font-size: 11px;
    }

    .Check-authonticity .first-section .text-holder-account-no p {
        font-size: 9px;
    }

    .Check-authonticity .first-section .text-holder-amount p {
        font-size: 9px;
    }

    .Check-authonticity .first-section .text-holder-type p {
        font-size: 9px;
    }

    .first-section .select-check-main .select-all-main .select-all-btn .select-img {
        width: 109px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox {
        top: 0;
        left: 15%;
    }

    .Check-authonticity .ok-btn-main .text-holder-ok .ok-btn {
        font-size: 16px;
    }

    .Check-authonticity .response-text {
        font-size: 17px;
    }
}

@media(min-width:1201px) and (max-width:1399px) {
    .Check-authonticity .title-check h5 {
        font-size: 18px;
    }

    .Check-authonticity .sub-title-check .text-holder-receivable p {
        font-size: 10px;
    }

    .Check-authonticity .sub-title-check .text-holder-receiv p {
        font-size: 10px;
    }

    .Check-authonticity .sub-title-check .text-holder-reject p {
        font-size: 10px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox label {
        color: #FFF;
        font-size: 11px;
    }

    .Check-authonticity .first-section .text-holder-account-no p {
        font-size: 8px;
    }

    .first-section .select-check-main .select-all-main .select-all-btn .select-img {
        width: 145px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox {
        top: 9%;
        left: 24%;
    }

    .Check-authonticity .ok-btn-main .text-holder-ok .ok-btn {
        font-size: 16px;
    }

    .Check-authonticity .first-section .text-holder-amount p {
        font-size: 8px;
    }
    .Check-authonticity .first-section .text-holder-type p {
        font-size: 9px;
    }
    .Check-authonticity .response-text {
        font-size: 16px;
    }
    .Check-authonticity .sub-title-check .text-holder-receivable {
        left: 14%;
    }
}

@media(min-width:1400px) and (max-width:1440px) {
    .Check-authonticity .title-check h5 {
        font-size: 18px;
    }

    .Check-authonticity .sub-title-check .text-holder-receivable p {
        font-size: 10px;
    }

    .Check-authonticity .sub-title-check .text-holder-receiv p {
        font-size: 10px;
    }

    .Check-authonticity .sub-title-check .text-holder-reject p {
        font-size: 10px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox label {
        color: #FFF;
        font-size: 11px;
    }

    .Check-authonticity .first-section .text-holder-account-no p {
        font-size: 8px;
    }

    .first-section .select-check-main .select-all-main .select-all-btn .select-img {
        width: 145px;
    }

    .Check-authonticity .first-section .select-check-main .select-all-main .check-chekbox {
        top: 9%;
        left: 24%;
    }

    .Check-authonticity .ok-btn-main .text-holder-ok .ok-btn {
        font-size: 16px;
    }

    .Check-authonticity .first-section .text-holder-amount p {
        font-size: 8px;
    }
    .Check-authonticity .first-section .text-holder-type p {
        font-size: 9px;
    }
    .Check-authonticity .response-text {
        font-size: 16px;
    }
    .Check-authonticity .sub-title-check .text-holder-receivable {
        left: 14%;
    }
}