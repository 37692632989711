@font-face {
    font-family: Gamer Station Extrude;
    src: url('../../../../public/assets/Image/Gamer\ Station\ Extrude.ttf');
}

.login-form {
    position: absolute;
    top: 12%;
    left: 7%;
    right: 7%;
}

.login-form .contact-us {
    position: relative;
}

.login-form .contact-us .contact-us-btn {
    font-size: 20px;
    border: none;
    cursor: pointer;
    color: #000;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 150px;
    height: auto;
    border: none;
    background: none;
    font-family: 'Gamer Station Extrude';
}

.login-form .contact-us .contact-us-text {
    position: absolute;
    top: 23%;
    left: 10%;
}

.login-form .contact-us .contact-us-text p {
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Gamer Station Extrude';
}

.login-form .contact-us .contact-img {
    width: 179px;
    height: auto;
}

/* .login-form .logged-img {
    width: 665px;
    height: 450px;
} */

.login-form .logged-img {
    width: 501px;
    height: 337px;
}

.login-form .close-btn-main .close-btn {
    border: none;
    background: none;
}

.login-form .close-btn-main .close-img {
    cursor: pointer;
    width: 70px;
    height: 70px;
    border: none;
}

.login-form .game-boy-main .game-boy-img {
    width: 167px;
    height: 182px;
}

.login-form .welcome-text {
    font-size: 61px;
    font-weight: 600;
    color: #005DFE;
    text-shadow: 0px 3px 6px #FF00DD;
}

.login-form .logged-form {
    position: relative;

}

.login-form .logged-form .text-holder-login {
    position: absolute;
    top: 11%;
    left: 23%;
}

.text-holder-login .title-form {
    font-size: 24px;
    color: #FFFFFF;
}

.login-form .start-btn-class {
    width: 103px;
    height: auto;
    background: #DF12EE;
    border: 2px solid #E50199;
    border-radius: 10px;
    opacity: 1;
    color: #FFF;
    font-size: 24px;
    padding: 2px 5px 7px 5px;
    font-family: 'Gamer Station Extrude';
}

.login-form form label {
    font-size: 18px;
    font-weight: 600;
    color: #FFF;
    font-family: 'Gamer Station Extrude';
}

.login-form form .input-group input {
    background: transparent;
    border: none;
    border-radius: 10px;
}

.login-form .text-holder-login .input-group {
    width: 100%;
    background-size: cover;
    height: 48px;
    background-image: url('/public/assets/Image/loginpage/background/input-bg.png');
}


.login-form .text-holder-login .input-group .form-control {
    color: #FFF;
}

/* Media Query */

@media(min-width:768px) and (max-width:992px) {
    .login-form .text-holder-login .input-group {
        width: 95%;
        height: 27px;
    }

    .login-form .contact-us .contact-img {
        width: 72%;
        margin-top: 3%;
    }

    .login-form .contact-us .contact-us-text p {
        font-size: 9px;
    }

    .login-form .contact-us .contact-us-text {
        top: 41%;
        left: 43%;
    }

    .login-form .welcome-text {
        font-size: 25px;
    }

    .text-holder-login .title-form {
        font-size: 15px;
    }

    .login-form form label {
        font-size: 12px;
    }

    .login-form .logged-img {
        width: 107%;
        height: auto;
    }

    .login-form .start-btn-class {
        font-size: 13px;
        padding: 2px 19px;
    }

    .login-form .close-btn-main .close-img {
        width: 45px;
        height: 45px;
    }

    .login-form .game-boy-main .game-boy-img {
        display: none;
    }

    .login-form {
        top: 5%;
        left: 3%;
        right: 10%;
    }

    .login-form .logged-form .text-holder-login {
        position: absolute;
        top: 11%;
        left: 3%;
    }

    .login-form form input {
        width: 111px;
    }

}

@media(min-width:993px) and (max-width:1200px) {
    .login-form .contact-us .contact-img {
        width: 100%;
        margin-top: 25%;
    }

    .login-form .contact-us .contact-us-text p {
        font-size: 15px;
    }

    .login-form .contact-us .contact-us-text {
        top: 58%;
        left: 26%;
    }

    .login-form .welcome-text {
        font-size: 35px;
    }

    .text-holder-login .title-form {
        font-size: 18px;
    }

    .login-form form label {
        font-size: 15px;
    }

    .login-form .logged-img {
        width: 500px;
        height: 280px;
    }

    .login-form .start-btn-class {
        width: fit-content;
        height: auto;
        background: #DF12EE;
        font-size: 16px;
        padding: 3px 31px;
    }

    .login-form {
        top: 5%;
        right: 12%;
    }

    .login-form .close-btn-main .close-img {
        width: 50px;
        height: 50px;
        margin-top: 35%;
    }

    .login-form .game-boy-main .game-boy-img {
        display: none;
    }

    .login-form .text-holder-login .input-group {
        height: 34px;
    }

}

@media(min-width:1201px) and (max-width:1440px) {
    .login-form {
        position: absolute;
        top: 8%;
        left: 7%;
        right: 7%;
    }
    .login-form .welcome-text {
        font-size: 50px;
    }

    .text-holder-login .title-form {
        font-size: 21px;
    }

    .login-form form label {
        font-size: 20px;
    }

    .login-form .logged-img {
        width: 521px;
        height: 327px;
    }

    .login-form .start-btn-class {
        width: fit-content;
        height: auto;
        background: #DF12EE;
        font-size: 17px;
        padding: 3px 31px;
    }

    .login-form .logged-form .text-holder-login {
        position: absolute;
        top: 11%;
        left: 12%;
    }

    .login-form .contact-us .contact-us-text p {
        font-size: 20px;
    }

    .login-form .contact-us .contact-us-text {
        position: absolute;
        top: 22%;
        left: 27%;
    }
}


@media(min-width:1441px) and (max-width:1600px) {
    .login-form {
        position: absolute;
        top: 8%;
        left: 7%;
        right: 7%;
    }
    .login-form .logged-form .text-holder-login {
        position: absolute;
        top: 11%;
        left: 14%;
    }
}

@media(min-width:1601px) and (max-width:2000px) {
    .login-form {
        position: absolute;
        top: 13%;
        left: 7%;
        right: 7%;
    }
    .login-form .logged-form .text-holder-login {
        position: absolute;
        top: 11%;
        left: 18%;
    }
}

@media(min-width:2201px) and (max-width:3000px) {
    .login-form .logged-form .text-holder-login {
        position: absolute;
        top: 11%;
        left: 27%;
    }
}