

/* .fun-triple-text {
    background-image: url('../../../../public//assets/Image/tripal-fun/Background.png');
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0%;
} */

.fun-triple-text .fun-triple-title h5{
    font-size: 77px;
    font-weight: 400;
    color: #FFF;
}

.fun-triple-text .score-text {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 0px;
    text-align: center;
}

.fun-triple-text .first-line-button-main {
    position: relative;
}

.fun-triple-text .first-line-button-main .first-line-button-text .first-line-button{
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    color: #000;
    font-size: 20px;
    font-weight: 600;
}

.fun-triple-text .first-line-button-main .yellow-img {
    width: 100%;
    height: 80px;
}

.fun-triple-text .first-line-button-main .yellow-middle-img{
    width: 250px;
    height: 80px;
}

.fun-triple-text .first-line-button-main .first-line-button-text {
    position: absolute;
    top: 24%;
    left: 45%;
}

.fun-triple-text .first-line-button-main .first-line-button-middle-text {
    position: absolute;
    top: 29%;
    left: 48%;
}

.fun-triple-text .first-line-button-main .first-line-button-middle-text .first-line-button{
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    color: #000;
    font-size: 20px;
    font-weight: 600;
}


@media(min-width:577px) and (max-width:768px) {
    .fun-triple-text .fun-triple-title h5 {
        font-size: 38px;
    }
    .fun-triple-text .score-text {
        font-size: 14px;
    }
}

@media(min-width:769px) and (max-width:992px) {
   
}

@media(min-width:992px) and (max-width:1200px) {
   
}

@media(min-width:1201px) and (max-width:1440px) {
    .fun-triple-text .fun-triple-title h5 {
        font-size: 57px;
    }
    .fun-triple-text .first-line-button-main .first-line-button-text .first-line-button {
        font-size: 17px;
    }
    .fun-triple-text .score-text {
        font-size: 17px;
    }
    .fun-right-table .left-empty-table-block .empty-brown {
        font-size: 18px;
        padding: 0px 9px;
    }
}

