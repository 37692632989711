.home-button .logged-id {
    position: relative;
}

.home-button .logged-id .logged-id-img .log-img {
    width: 397px;
    height: 262px;
}

.home-button .logged-id .text-holder-logged {
    position: absolute;
    top: 12%;
    left: 24%;
}


.home-button .logged-id .text-holder-logged p {
    font-size: 25px;
    color: #FFF;
    font-weight: 600;
    font-family: 'Gamer Station Extrude';
}


.home-button .logged-id .text-holder-logged .white-line {
    border-bottom: 1px solid #FFF;
}

.home-button .logged-id .text-holder-points .white-line {
    border-bottom: 1px solid #FFF;
}

.home-button .logged-id .text-holder-points {
    position: absolute;
    top: 60%;
    left: 24%;
}

.home-button .logged-id .text-holder-points p {
    font-size: 25px;
    color: #FFF;
    font-weight: 600;
    font-family: 'Gamer Station Extrude';
}

/* next button */

.home-button .gk-point-main {
    position: relative;
}

.home-button .gk-point-main .gk-point-img .gk-img {
    width: 199px;
    margin-top: 5%;
}

.home-button .gk-point-main .empty-circle {
    position: absolute;
    top: 41%;
    left: 61%;
}

.home-button .gk-point-main .empty-circle .empty-circle-img {
    width: 44px;
}

.home-button .gk-point-main .text-holder-gk {
    position: absolute;
    top: 55%;
    left: 39%;
}

.home-button .gk-point-main .text-holder-gk p {
    font-size: 15px;
    color: #FFF;
    font-weight: 600;
    font-family: 'Gamer Station Extrude';
}

/* Pointer transfer */

.home-button .point-transfer-main {
    position: relative;
}

.home-button .point-transfer-main .point-transfer .bg-img {
    width: 250px;
}

.home-button .point-transfer-main .shape-box {
    position: absolute;
    top: -22%;
    left: 23%;
}

.home-button .point-transfer-main .shape-box .shape-img {
    width: 66px;
}

.home-button .point-transfer-main .text-holder-pointer {
    position: absolute;
    top: 9%;
    left: 34%;
}

.home-button .point-transfer-main .text-holder-pointer p {
    font-size: 20px;
    color: #FFF;
    font-family: 'Gamer Station Extrude';
}

/* setting button */

.home-button .setting-main {
    position: relative;
}

.home-button .setting-img-main .setting-bg-img {
    width: 250px;
}

.home-button .setting-main .text-holder-setting {
    position: absolute;
    top: 24%;
    left: 33%;
}

.home-button .setting-main .text-holder-setting .setting-img {
    width: 43px;
}

.home-button .setting-main .text-holder-setting p {
    font-size: 22px;
    color: #FFF;
    font-family: 'Gamer Station Extrude';
}

.home-button .refresh-btn {
    background: transparent;
    border: none;
    color: #FFF;
    font-size: 22px;
    font-family: 'Gamer Station Extrude';
}

.home-button .refresh-btn .refresh-img {
    width: 44px;
}

@media(min-width:577px) and (max-width:768px) {
    .home-button .logged-id .logged-id-img .log-img {
        width: 150px;
        height: 80px;
    }

    .home-button .logged-id .text-holder-logged p {
        font-size: 10px;
    }

    .home-button .logged-id .text-holder-points .white-line {
        border-bottom: 0px;
    }

    .home-button .logged-id .text-holder-points p {
        font-size: 10px;
    }

    .home-button .gk-point-main .gk-point-img .gk-img {
        width: 152px;
        margin-top: 3%;
    }

    .home-button .gk-point-main .text-holder-gk p {
        font-size: 9px;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 41%;
        left: 24%;
    }

    .home-button .gk-point-main .empty-circle .empty-circle-img {
        width: 31px;
    }

    .home-button .gk-point-main .empty-circle {
        top: 16%;
        left: 72%;
    }

    .home-button .point-transfer-main .point-transfer .bg-img {
        width: 152px;
    }

    .home-button .point-transfer-main .shape-box .shape-img {
        width: 30px;
    }

    .home-button .point-transfer-main .shape-box {
        top: -5%;
        left: 17%;
    }

    .home-button .point-transfer-main .text-holder-pointer p {
        font-size: 12px;
    }

    .home-button .setting-img-main .setting-bg-img {
        width: 124px;
    }

    .home-button .setting-main .text-holder-setting .setting-img {
        width: 18px;
    }

    .home-button .setting-main .text-holder-setting {
        top: 22%;
        left: 30%;
    }

    .home-button .setting-main .text-holder-setting p {
        font-size: 12px;
    }

    .home-button .refresh-btn .refresh-img {
        width: 18px;
    }

    .home-button .refresh-btn {
        font-size: 10px;
    }

}

@media(min-width:769px) and (max-width:992px) {
    .home-button .logged-id .logged-id-img .log-img {
        width: 180px;
        height: 140px;
        margin-top: 4%;
    }

    .home-button .logged-id .text-holder-logged {
        top: 22%;
        left: 24%;
    }

    .home-button .logged-id .text-holder-logged p {
        font-size: 11px;
    }

    .home-button .logged-id .text-holder-points .white-line {
        border-bottom: 2px solid #FFF;
    }

    .home-button .logged-id .text-holder-points p {
        font-size: 10px;
    }

    .home-button .gk-point-main .gk-point-img .gk-img {
        width: 152px;
        margin-top: 3%;
    }

    .home-button .gk-point-main .text-holder-gk p {
        font-size: 10px;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 41%;
        left: 30%;
    }

    .home-button .gk-point-main .empty-circle .empty-circle-img {
        width: 31px;
    }

    .home-button .gk-point-main .empty-circle {
        top: 16%;
        left: 72%;
    }

    .home-button .point-transfer-main .point-transfer .bg-img {
        width: 152px;
    }

    .home-button .point-transfer-main .shape-box .shape-img {
        width: 30px;
    }

    .home-button .point-transfer-main .shape-box {
        top: -5%;
        left: 17%;
    }

    .home-button .point-transfer-main .text-holder-pointer p {
        font-size: 13px;
    }

    .home-button .setting-img-main .setting-bg-img {
        width: 147px;
        margin-top: 4%;
    }

    .home-button .setting-main .text-holder-setting .setting-img {
        width: 18px;
    }

    .home-button .setting-main .text-holder-setting {
        top: 40%;
        left: 30%;
    }

    .home-button .setting-main .text-holder-setting p {
        font-size: 16px;
    }

    .home-button .refresh-btn .refresh-img {
        width: 18px;
    }

    .home-button .refresh-btn {
        font-size: 12px;
    }

    .home-button .point-transfer-main .text-holder-pointer {
        top: 19%;
        left: 30%;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .home-button .logged-id .logged-id-img .log-img {
        width: 195px;
        height: 111px;
    }

    .home-button .logged-id .text-holder-logged p {
        font-size: 13px;
    }

    .home-button .logged-id .text-holder-points p {
        font-size: 13px;
    }

    .home-button .logged-id .text-holder-points {
        top: 51%;
    }

    .home-button .gk-point-main .gk-point-img .gk-img {
        width: 127px;
    }

    .home-button .gk-point-main .text-holder-gk p {
        font-size: 10px;
    }

    .home-button .point-transfer-main .shape-box .shape-img {
        width: 42px;
    }

    .home-button .point-transfer-main .shape-box {
        top: -19%;
        left: 18%;
    }

    .home-button .point-transfer-main .text-holder-pointer p {
        font-size: 14px;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 45%;
        left: 33%;
    }

    .home-button .gk-point-main .empty-circle .empty-circle-img {
        width: 35px;
    }

    .home-button .gk-point-main .empty-circle {
        top: 26%;
        left: 61%;
    }

    .home-button .point-transfer-main .point-transfer .bg-img {
        width: 145px;
    }

    .home-button .setting-main .text-holder-setting {
        top: 17%;
        left: 28%;
    }

    .home-button .setting-img-main .setting-bg-img {
        width: 177px;
    }

    .home-button .setting-main .text-holder-setting .setting-img {
        width: 32px;
    }

    .home-button .setting-main .text-holder-setting p {
        font-size: 16px;
    }

    .home-button .refresh-btn .refresh-img {
        width: 32px;
    }

    .home-button .refresh-btn {
        font-size: 14px;
    }
}

@media(min-width:1201px) and (max-width:1440px) {
    .home-button .logged-id .text-holder-logged {
        top: 12%;
        left: 34%;
    }

    .home-button .logged-id .logged-id-img .log-img {
        width: 195px;
        height: 111px;
    }

    .home-button .logged-id .text-holder-logged p {
        font-size: 13px;
    }

    .home-button .logged-id .text-holder-points p {
        font-size: 13px;
    }

    .home-button .logged-id .text-holder-points {
        top: 51%;
        left: 34%;
    }

    .home-button .gk-point-main .gk-point-img .gk-img {
        width: 127px;
    }

    .home-button .gk-point-main .text-holder-gk p {
        font-size: 12px;
    }

    .home-button .point-transfer-main .shape-box .shape-img {
        width: 42px;
    }

    .home-button .point-transfer-main .shape-box {
        top: -19%;
        left: 28%;
    }

    .home-button .point-transfer-main .text-holder-pointer p {
        font-size: 14px;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 48%;
        left: 39%;
    }

    .home-button .gk-point-main .empty-circle .empty-circle-img {
        width: 35px;
    }

    .home-button .gk-point-main .empty-circle {
        top: 26%;
        left: 61%;
    }

    .home-button .point-transfer-main .point-transfer .bg-img {
        width: 145px;
    }

    .home-button .setting-main .text-holder-setting {
        top: 17%;
        left: 34%;
    }

    .home-button .setting-img-main .setting-bg-img {
        width: 177px;
    }

    .home-button .setting-main .text-holder-setting .setting-img {
        width: 32px;
    }

    .home-button .setting-main .text-holder-setting p {
        font-size: 19px;
    }

    .home-button .refresh-btn .refresh-img {
        width: 32px;
    }

    .home-button .refresh-btn {
        font-size: 18px;
    }

    .home-button .point-transfer-main .text-holder-pointer {
        left: 40%;
    }
}

@media(min-width:2201px) and (max-width:3000px) {
    .home-button .logged-id .text-holder-logged {
        top: 16%;
        left: 31%;
    }

    .home-button .logged-id .text-holder-points {
        top: 60%;
        left: 32%;
    }

    .home-button .gk-point-main .empty-circle {
        top: 46%;
        left: 58%;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 52%;
        left: 40%;
    }

    .home-button .point-transfer-main .shape-box {
        top: -23%;
        left: 32%;
    }

    .home-button .point-transfer-main .text-holder-pointer {
        top: 9%;
        left: 42%;
    }

    .home-button .setting-main .text-holder-setting {
        top: 24%;
        left: 39%;
    }
}