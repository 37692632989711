.fun-table .red-bg {
    width: 66px;
    height: 66px;
    background: transparent linear-gradient(180deg, #CB978D 0%, #AF4128 25%, #A7391F 46%, #AA391F 75%, #D4978B 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;
    padding: 3px;
    border: 3px solid #D4AE50;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 32px;
    margin-top: 10px;
}


.fun-table .green-bg {
    width: 66px;
    height: 66px;
    background: transparent linear-gradient(180deg, #B4B190 0%, #161C0B 16%, #161C0B 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;
    padding: 3px;
    border: 3px solid #D4AE50;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 32px;
    margin-top: 10px;
}

.fun-table .yellow-border {
    border: 2px solid #FFFF4B;
    padding: 0px 25px 0px 25px;
}

.fun-table .lastline-img {
    width: 60%;
    margin-top: 1%;
}

.fun-table .last-btn {
    position: relative;
}

.fun-table .last-btn .back-btn-class {
    border: none;
    font-size: 14px;
    font-weight: 700;
    color: #FFF;
}

.fun-table .last-btn .back-btn-main {
    position: absolute;
    left: 39px;
    top: 2px;
}

.fun-table p.minimum-text {
    color: #FFF;
    font-size: 20px;
}

.fun-table .left-button-img {
    width: 100%;
}

/* funtable */

.sub-table {
    margin-left: 6%;
}

.sub-table .table, td {
    border: 2px solid #FFFF4B;
    color: #FFF;
    font-size: 25px;
    padding: 8px 100px;
  }

  .sub-table .table, th {
    border: 2px solid #FFFF4B;
    color: #63FF46;
    font-size: 25px;
    font-weight: 600;
  }

  .sub-table .polygon-img {
    width: 60px;
    height: 21px;
  }
