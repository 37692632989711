.spinner {
    position: relative;
}

.spinner .old-img {
    position: absolute;
    top: 0;
    z-index: 9.9999;
}

.spinner .tripe-img-main {
    position: absolute;
    top: 8px;
    left: 4px;
}

.spinner .tripe-img {
    width: 431px;
    z-index: 9999;
    position: relative;
}