.fun-darbhar-button {
    position: relative;
}

/* Red Round */
.fun-darbhar-button .red-round-and-card {
    position: absolute;
    top: -55%;
    left: 0;
}

.fun-darbhar-button .red-round-and-card .round-img {
    width: 100%;
    height: 100px;
}

/* Green Round */

.fun-darbhar-button .green-round-and-card {
    position: absolute;
    top: -29%;
    left: 9%;
}

.fun-darbhar-button .green-round-and-card .round-img {
    width: 100%;
    height: 100px;
}

/* yellow Round */

.fun-darbhar-button .yellow-round-and-card {
    position: absolute;
    top: -7%;
    left: 19%;
}

.fun-darbhar-button .yellow-round-and-card .round-img {
    width: 100%;
    height: 100px;
}

/* dr-blue round */

.fun-darbhar-button .dr-blue-round-and-card {
    position: absolute;
    top: 13%;
    left: 29%;
}

.fun-darbhar-button .dr-blue-round-and-card .round-img {
    width: 100%;
    height: 100px;
}

/* dark-blue round */

.fun-darbhar-button .dark-blue-round-and-card {
    position: absolute;
    top: 12%;
    left: 65%;
}

.fun-darbhar-button .dark-blue-round-and-card .round-img {
    width: 100%;
    height: 100px;
}

/* light-blue round */

.fun-darbhar-button .light-blue-round-and-card {
    position: absolute;
    top: 0%;
    left: 75%;
}

.fun-darbhar-button .light-blue-round-and-card .round-img {
    width: 100%;
    height: 100px;
}

/* Blue round */

.fun-darbhar-button .blue-round-and-card {
    position: absolute;
    bottom: 78%;
    left: 85%;
}

.fun-darbhar-button .blue-round-and-card .round-img {
    width: 100%;
    height: 100px;
}

/* Orange round */

.fun-darbhar-button .orange-round-and-card {
    position: absolute;
    top: -53%;
    left: 93%;
}

.fun-darbhar-button .orange-round-and-card .round-img {
    width: 100%;
    height: 100px;
}

/* Common for all round */

.fun-darbhar-button .round-img-main {
    position: relative;
}

.fun-darbhar-button .round-img-main .number-class {
    position: absolute;
    top: 29%;
    left: 40%;
    color: #323236C4;
    font-size: 20px;
    font-weight: 600;
}

/* Cards */

.fun-darbhar-button .black-img-main-first {
    position: absolute;
    top: 20%;
    right: 10%;
}

.fun-darbhar-button .black-img-main-first .cards-img {
    width: 75px;
    height: 85px;
}

.fun-darbhar-button .black-img-main-second {
    position: absolute;
    top: 23%;
    right: 5%;
}

.fun-darbhar-button .black-img-main-second .cards-img {
    width: 75px;
    height: 85px;
}

.fun-darbhar-button .black-img-main-third {
    position: absolute;
    top: 23%;
    left: 0%;
}

.fun-darbhar-button .black-img-main-third .cards-img {
    width: 75px;
    height: 85px;
}

.fun-darbhar-button .black-img-main-four {
    position: absolute;
    top: 23%;
    left: 5%;
}

.fun-darbhar-button .black-img-main-four .cards-img {
    width: 75px;
    height: 85px;
}

.fun-darbhar-button .black-img-main-five {
    position: absolute;
    top: 21%;
    left: 10%;
}

.fun-darbhar-button .black-img-main-five .cards-img {
    width: 75px;
    height: 85px;
}

/* Cards end */

.fun-darbhar-button .fun-take-button {
    position: absolute;
    top: 56%;
    left: 15%;
}

.fun-darbhar-button .fun-take-button .sub-fun-take {
    position: relative;
}

.fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img {
    position: absolute;
    top: -12px;
    left: 0;
}

.fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img .ellips-img {
    width: 58px;
    height: 58px;
}


.fun-darbhar-button .fun-ab-button {
    background: #272C43;
    border: 2px solid #D4AE50;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    width: 164px;
    height: 32px;
    border-radius: 25px;

}

/* Exit button */
.fun-darbhar-button .fun-exit-button {
    position: absolute;
    top: 27%;
    right: 1%;
}

.fun-darbhar-button .fun-exit-button .sub-fun-exit {
    position: relative;
}

.fun-darbhar-button .fun-exit-button .sub-fun-exit .take-btn-img {
    position: absolute;
    top: -35%;
    left: 72%;
}

.fun-darbhar-button .fun-exit-button .sub-fun-exit .take-btn-img .ellips-img {
    width: 58px;
    height: 58px;
}

/* Bet ok button */

.fun-darbhar-button .fun-take-bet-ok-button {
    position: absolute;
    top: 55%;
    right: 9%;
}

.fun-darbhar-button .fun-take-bet-ok-button .sub-fun-bet-ok {
    position: relative;
}

.fun-darbhar-button .fun-take-bet-ok-button .sub-fun-bet-ok .take-btn-img {
    position: absolute;
    top: -35%;
    left: 72%;
}

.fun-darbhar-button .fun-take-bet-ok-button .sub-fun-bet-ok .take-btn-img .ellips-img {
    width: 58px;
    height: 58px;
}

/* zero button */

.fun-darbhar-button .fun-total-bet-button {
    position: absolute;
    top: 73%;
    right: 1%;
}

.fun-darbhar-button .fun-total-bet-button .total-bet-text {
    color: #FFF;
    line-height: 1px;
}


/* .fun-darbhar-button .fun-ab-button .ellips-img {
   width: 50px;
   height: 50px;

} */

.fun-darbhar-button .bet-time-over .bet-time-over-button {
    width: 100%;
    margin-top: 9%;
    background: #272C43;
    border: 2px solid #D4AE50;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    height: 35px;
    border-radius: 25px;
}

/* tab view css */
.desktop-view-btn {
    display: block;
}

.tab-view-button {
    display: none;
}

.tab-view-button .take-btn {
    width: 150px;
    margin-top: 9%;
    background: #272C43;
    border: 2px solid #D4AE50;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    height: 30px;
    border-radius: 25px;
    padding: 1px;
}

/* tab view end */


@media(min-width:577px) and (max-width:768px) {
    .desktop-view-btn {
        display: none;
    }

    .tab-view-button {
        display: block;
    }

    .main-fun-darbhar .women-img-main {
        top: 50%;
    }
    .tab-view-button .take-btn {
        width: 80px;
        margin-top: 11%;
        font-size: 10px;
        padding: 0px;
    }

    .fun-darbhar-button .red-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .green-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .yellow-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .dr-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .dark-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .light-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .orange-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .round-img-main .number-class {
        font-size: 13px;
    }

    .fun-darbhar-button .red-round-and-card {
        top: 0%;
        left: 0;
    }

    .fun-darbhar-button .green-round-and-card {
        top: 12%;
        left: 9%;
    }

    .fun-darbhar-button .yellow-round-and-card {
        top: 19%;
        left: 18%;
    }

    .fun-darbhar-button .dr-blue-round-and-card {
        top: 27%;
        left: 27%;
    }

    .fun-darbhar-button .black-img-main-first .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-first {
        top: 30%;
        right: 12%;
    }

    .fun-darbhar-button .black-img-main-second .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-second {
        top: 31%;
        right: 7%;
    }

    .fun-darbhar-button .black-img-main-third .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-third {
        top: 31%;
        left: -2%;
    }

    .fun-darbhar-button .black-img-main-four .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-four {
        top: 30%;
        left: 3%;
    }

    .fun-darbhar-button .black-img-main-five .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-five {
        top: 29%;
        left: 8%;
    }

    .fun-darbhar-button .dark-blue-round-and-card {
        top: 25%;
        left: 61%;
    }

    .fun-darbhar-button .light-blue-round-and-card {
        top: 20%;
        left: 70%;
    }

    .fun-darbhar-button .blue-round-and-card {
        bottom: 41%;
        left: 80%;
    }

    .fun-darbhar-button .orange-round-and-card {
        top: 1%;
        left: 90%;
    }

    .fun-darbhar-button .fun-ab-button {
        font-size: 14px;
        width: 112px;
    }

    .fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .fun-darbhar-button .fun-take-button {
        top: 66%;
        left: 1%;
    }

    .fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img {
        top: 0;
        left: 0;
    }

    .fun-darbhar-button .bet-time-over .bet-time-over-button {
        margin-top: 11%;
        font-size: 15px;
        height: 26px;
    }

    .fun-darbhar-button .fun-exit-button .sub-fun-exit .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

}

@media(min-width:769px) and (max-width:992px) {
    .desktop-view-btn {
        display: none;
    }

    .tab-view-button {
        display: block;
    }

    .main-fun-darbhar .women-img-main {
        top: 52%;
    }

    .fun-darbhar-button .red-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .green-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .yellow-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .dr-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .dark-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .light-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .orange-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .round-img-main .number-class {
        font-size: 13px;
    }

    .fun-darbhar-button .red-round-and-card {
        top: 0%;
        left: 0;
    }

    .fun-darbhar-button .green-round-and-card {
        top: 12%;
        left: 9%;
    }

    .fun-darbhar-button .yellow-round-and-card {
        top: 19%;
        left: 18%;
    }

    .fun-darbhar-button .dr-blue-round-and-card {
        top: 27%;
        left: 27%;
    }

    .fun-darbhar-button .black-img-main-first .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-first {
        top: 30%;
        right: 12%;
    }

    .fun-darbhar-button .black-img-main-second .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-second {
        top: 31%;
        right: 7%;
    }

    .fun-darbhar-button .black-img-main-third .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-third {
        top: 31%;
        left: -2%;
    }

    .fun-darbhar-button .black-img-main-four .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-four {
        top: 30%;
        left: 3%;
    }

    .fun-darbhar-button .black-img-main-five .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-five {
        top: 29%;
        left: 8%;
    }

    .fun-darbhar-button .dark-blue-round-and-card {
        top: 21%;
        left: 61%;
    }

    .fun-darbhar-button .light-blue-round-and-card {
        top: 20%;
        left: 70%;
    }

    .fun-darbhar-button .blue-round-and-card {
        bottom: 41%;
        left: 80%;
    }

    .fun-darbhar-button .orange-round-and-card {
        top: 1%;
        left: 90%;
    }

    .fun-darbhar-button .fun-ab-button {
        font-size: 14px;
        width: 112px;
    }

    .fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .fun-darbhar-button .fun-take-button {
        top: 66%;
        left: 1%;
    }

    .fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img {
        top: 0;
        left: 0;
    }

    .fun-darbhar-button .bet-time-over .bet-time-over-button {
        margin-top: 11%;
        font-size: 15px;
        height: 26px;
    }

    .fun-darbhar-button .fun-exit-button .sub-fun-exit .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .score-winner .first-card-img {
        width: 48%;
    }

    .score-winner .bg-score .first-card-img-main {
        top: 5px;
    }

}

@media(min-width:992px) and (max-width:1200px) {
    .desktop-view-btn {
        display: none;
    }

    .tab-view-button {
        display: block;
    }

    .main-fun-darbhar .women-img-main {
        top: 56%;
    }

    .main-fun-darbhar .women-img-main .women-img {
        width: 180px;
        height: 180px;
    }

    .fun-darbhar-button .red-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .green-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .yellow-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .dr-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .dark-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .light-blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .blue-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .orange-round-and-card .round-img {
        width: 60px;
        height: 60px;
    }

    .fun-darbhar-button .round-img-main .number-class {
        font-size: 13px;
    }

    .fun-darbhar-button .red-round-and-card {
        top: -13%;
        left: 2%;
    }

    .fun-darbhar-button .green-round-and-card {
        top: 0%;
        left: 10%;
    }

    .fun-darbhar-button .yellow-round-and-card {
        top: 13%;
        left: 18%;
    }

    .fun-darbhar-button .dr-blue-round-and-card {
        top: 22%;
        left: 27%;
    }

    .fun-darbhar-button .black-img-main-first .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-first {
        top: 28%;
        right: 12%;
    }

    .fun-darbhar-button .black-img-main-second .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-second {
        top: 30%;
        right: 7%;
    }

    .fun-darbhar-button .black-img-main-third .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-third {
        top: 31%;
        left: -2%;
    }

    .fun-darbhar-button .black-img-main-four .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-four {
        top: 31%;
        left: 3%;
    }

    .fun-darbhar-button .black-img-main-five .cards-img {
        width: 30px;
        height: 50px;
    }

    .fun-darbhar-button .black-img-main-five {
        top: 29%;
        left: 8%;
    }

    .fun-darbhar-button .dark-blue-round-and-card {
        top: 23%;
        left: 63%;
    }

    .fun-darbhar-button .light-blue-round-and-card {
        top: 16%;
        left: 72%;
    }

    .fun-darbhar-button .blue-round-and-card {
        bottom: 51%;
        left: 81%;
    }

    .fun-darbhar-button .orange-round-and-card {
        top: -9%;
        left: 91%;
    }

    .fun-darbhar-button .fun-ab-button {
        font-size: 14px;
        width: 112px;
    }

    .fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .fun-darbhar-button .fun-take-button {
        top: 66%;
        left: 1%;
    }

    .fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img {
        top: 0;
        left: 0;
    }

    .fun-darbhar-button .bet-time-over .bet-time-over-button {
        margin-top: 11%;
        font-size: 15px;
        height: 26px;
    }

    .fun-darbhar-button .fun-exit-button .sub-fun-exit .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .fun-darbhar-button .fun-exit-button .sub-fun-exit .take-btn-img {
        top: 0%;
        left: 70%;
    }

    .fun-darbhar-button .fun-take-bet-ok-button .sub-fun-bet-ok .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .fun-darbhar-button .fun-take-bet-ok-button .sub-fun-bet-ok .take-btn-img {
        top: 0;
    }

}

@media(min-width:1201px) and (max-width:1440px) {
    .fun-darbhar-button .red-round-and-card .round-img {
        width: 70px;
        height: 70px;
    }

    .fun-darbhar-button .green-round-and-card .round-img {
        width: 70px;
        height: 70px;
    }

    .fun-darbhar-button .yellow-round-and-card .round-img {
        width: 70px;
        height: 70px;
    }

    .fun-darbhar-button .dr-blue-round-and-card .round-img {
        width: 70px;
        height: 70px;
    }

    .fun-darbhar-button .dark-blue-round-and-card .round-img {
        width: 70px;
        height: 70px;
    }

    .fun-darbhar-button .light-blue-round-and-card .round-img {
        width: 70px;
        height: 70px;
    }

    .fun-darbhar-button .blue-round-and-card .round-img {
        width: 70px;
        height: 70px;
    }

    .fun-darbhar-button .orange-round-and-card .round-img {
        width: 70px;
        height: 70px;
    }

    .fun-darbhar-button .round-img-main .number-class {
        font-size: 13px;
    }

    .fun-darbhar-button .red-round-and-card {
        top: -14%;
        left: 4%;
    }

    .fun-darbhar-button .green-round-and-card {
        top: 0%;
        left: 12%;
    }

    .fun-darbhar-button .yellow-round-and-card {
        top: 14%;
        left: 20%;
    }

    .fun-darbhar-button .dr-blue-round-and-card {
        top: 22%;
        left: 28%;
    }

    .fun-darbhar-button .black-img-main-first .cards-img {
        width: 50px;
        height: 68px;
    }

    .fun-darbhar-button .black-img-main-first {
        top: 27%;
        right: 12%;
    }

    .fun-darbhar-button .black-img-main-second .cards-img {
        width: 50px;
        height: 68px;
    }

    .fun-darbhar-button .black-img-main-second {
        top: 30%;
        right: 7%;
    }

    .fun-darbhar-button .black-img-main-third .cards-img {
        width: 50px;
        height: 68px;
    }

    .fun-darbhar-button .black-img-main-third {
        top: 31%;
        left: -2%;
    }

    .fun-darbhar-button .black-img-main-four .cards-img {
        width: 50px;
        height: 68px;
    }

    .fun-darbhar-button .black-img-main-four {
        top: 30%;
        left: 3%;
    }

    .fun-darbhar-button .black-img-main-five .cards-img {
        width: 50px;
        height: 68px;
    }

    .fun-darbhar-button .black-img-main-five {
        top: 29%;
        left: 8%;
    }

    .fun-darbhar-button .dark-blue-round-and-card {
        top: 27%;
        left: 63%;
    }

    .fun-darbhar-button .light-blue-round-and-card {
        top: 18%;
        left: 72%;
    }

    .fun-darbhar-button .blue-round-and-card {
        bottom: 55%;
        left: 81%;
    }

    .fun-darbhar-button .orange-round-and-card {
        top: -11%;
        left: 90%;
    }

    .fun-darbhar-button .fun-ab-button {
        font-size: 14px;
        width: 112px;
    }

    .fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .fun-darbhar-button .fun-take-button {
        top: 66%;
        left: 1%;
    }

    .fun-darbhar-button .fun-take-button .sub-fun-take .take-btn-img {
        top: 0;
        left: 0;
    }

    .fun-darbhar-button .bet-time-over .bet-time-over-button {
        margin-top: 11%;
        font-size: 15px;
        height: 26px;
    }

    .fun-darbhar-button .fun-exit-button .sub-fun-exit .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .fun-darbhar-button .fun-exit-button .sub-fun-exit .take-btn-img {
        top: -8%;
        left: 70%;
    }

    .fun-darbhar-button .fun-exit-button {
        top: 43%;
    }

    .fun-darbhar-button .fun-take-bet-ok-button .sub-fun-bet-ok .take-btn-img .ellips-img {
        width: 35px;
        height: 35px;
    }

    .fun-darbhar-button .fun-take-bet-ok-button .sub-fun-bet-ok .take-btn-img {
        top: -5%;
    }

    .fun-darbhar-button .fun-take-bet-ok-button {
        top: 63%;
        right: 10%;
    }

    .fun-darbhar-button .fun-total-bet-button .total-bet-text {
        line-height: 0px;
        font-size: 12px;
    }

    .fun-darbhar-button .fun-total-bet-button {
        top: 69%;
    }

}

@media(min-width:2201px) and (max-width: 3000px) {

    /* .fun-darbhar-button .red-round-and-card {
        top: -8%;
        left: 1%;
    }
    .fun-darbhar-button .bet-time-over .bet-time-over-button {
        margin-top: 15%;
    }
    .fun-darbhar-button .green-round-and-card {
        top: 10%;
    }
    .fun-darbhar-button .yellow-round-and-card {
        top: 28%;
    }
    .fun-darbhar-button .dr-blue-round-and-card {
        top: 39%;
    }
    .fun-darbhar-button .dr-blue-round-and-card {
        top: 39%;
    }
    .fun-darbhar-button .dr-blue-round-and-card {
        top: 39%;
    }
    .fun-darbhar-button .blue-round-and-card {
        top: 14%;
    }
    .fun-darbhar-button .orange-round-and-card {
        top: -3%;
    }
    .fun-darbhar-button .black-img-main-first .cards-img {
        width: 95px;
        height: 107px;
    }
    .fun-darbhar-button .black-img-main-first {
        top: 43%;
    }
    .fun-darbhar-button .black-img-main-second {
        top: 45%;
    }
    .fun-darbhar-button .black-img-main-third .cards-img {
        width: 95px;
        height: 107px;
    }
    .fun-darbhar-button .black-img-main-third {
        top: 46%;
    }
    .fun-darbhar-button .black-img-main-four .cards-img {
        width: 95px;
        height: 107px;
    }
    .fun-darbhar-button .black-img-main-four {
        top: 46%;
    }
    .fun-darbhar-button .black-img-main-five {
        top: 43%;
    } */
    .score-winner .cards-img {
        margin-top: 30%;
        width: 100%;
    }

    .score-winner .first-card-img {
        width: 56%;
    }

    .score-winner .bg-score .first-card-img-main {
        top: 7px;
        left: 30px;
    }

    .fun-darbhar-button .black-img-main-first .cards-img {
        width: 95px;
        height: 101px;
    }

    .fun-darbhar-button .black-img-main-second .cards-img {
        width: 95px;
        height: 101px;
    }

    .fun-darbhar-button .black-img-main-third .cards-img {
        width: 95px;
        height: 101px;
    }

    .fun-darbhar-button .black-img-main-four .cards-img {
        width: 95px;
        height: 101px;
    }

    .fun-darbhar-button .black-img-main-five .cards-img {
        width: 95px;
        height: 101px;
    }
}