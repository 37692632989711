.main-fun-darbhar {
    background-image: url('../../../../public//assets//Image/fundarbhar/Mask\ Group\ 9.png');
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0%;
    position: relative;
}

.main-fun-darbhar .women-img-main {
    position: fixed;
    bottom: 0;
    left: 0;
}

.main-fun-darbhar .women-img-main .women-img {
    width: 367px;
    height: 392px;
}

