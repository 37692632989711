.main-home {
    position: absolute;
    top: 8%;
    left: 3%;
}



@media(min-width:577px) and (max-width:768px) {
    .main-home {
        top: 0%;
        left: 0;
    }

    .game .game-img-main .game-img {
        width: 76%;
    }

    .game .games-title {
        top: 8%;
        left: 43%;
    }

    .game .gamed-list p {
        font-size: 13px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .main-home {
        position: absolute;
        top: 0%;
        left: 0;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .main-home {
        position: absolute;
        top: 2%;
        left: 3%;
    }
}