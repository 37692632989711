/* Common Button css start*/
.common-button-main {
    position: relative;
}

.common-button-main .single-img {
    width: 150px;
    height: 60px;
}

.common-button-main .sub-common-button {
    position: absolute;
    top: 23%;
    left: 42%;
}

.common-button-main .first-line-button {
    border: none;
    background: transparent;
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

/* Common Button css end*/

.upper-img .upper-design-img {
    width: 125px;
    height: 31px;
}

.fun-triple-button .blue-bg {
    background: transparent linear-gradient(180deg, #152172 0%, #5A68CC 54%, #152172 100%);
    box-shadow: inset 0px 0px 20px #000F78;
    border: 2px solid #D4AE50;
    border-radius: 50px;
    padding: 1% 3%;
}

.fun-triple-button .last-number {
    color: #000;
    font-weight: 700;
    font-size: 25px;
    margin-right: 3%;
}

.fun-triple-button .number-class {
    color: #FFF;
    font-weight: 600;
    font-size: 25px;
    border-left: 3px solid #FFF;
    padding: 0% 5% 0% 5%;
}

.fun-triple-button .number-line {
    border: 2px solid #EDEA98;
    width: fit-content;
    text-align: center;
}

.fun-triple-button .number-line .brown-bg {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    color: #FFF;
    font-size: 20px;
    padding: 2px 15px;
}

.fun-triple-button .number-line .yellow-bg {
    background: transparent linear-gradient(180deg, #F8D70C 0%, #F0E56F 50%, #FDD60C 100%);
    color: #000;
    font-size: 20px;
    padding: 2px 15px;
}

.common-button-main .single-common-button {
    position: absolute;
    top: 20%;
    left: 83%;
}

.common-button-main .take-common-button {
    position: absolute;
    top: 22%;
    left: 6%;
}

.common-button-main .bet-ok-button {
    position: absolute;
    top: 24%;
    left: 78%;
}

.fun-triple-button .digit-round-main  {
    position: relative;
}

.fun-triple-button .digit-round-main .digit-img {
    width: 84px;
    height: 65px;
}
.fun-triple-button .digit-round-main .first-digit-class {
    position: absolute;
    top: 33%;
    left: 48%;
}

.fun-triple-button .digit-round-main .two-digit-class {
    position: absolute;
    top: 33%;
    left: 40%;
}

.fun-triple-button .digit-round-main .third-digit-class {
    position: absolute;
    top: 33%;
    left: 36%;
}

.fun-triple-button .digit-round-main .four-digit-class {
    position: absolute;
    top: 33%;
    left: 30%;
}

.fun-triple-button .digit-round-main .digit-round {
   font-size: 16px;
   font-weight: 600;
   color: #FFF;
}

/* Third Row */

.fun-triple-button .exit-button-class .exit-button {
    background: transparent linear-gradient(180deg, #E501998C 0%, #F27FCBA8 53%, #73014D8C 100%);
    box-shadow: inset 0px 0px 24px #A80371;
    border: 2px solid #D4AE50;
    border-radius: 20px 0px 0px 20px;
    width: 100%;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.fun-triple-button .zero-button-class .zero-button {
    background: transparent linear-gradient(180deg, #E501998C 0%, #F27FCBA8 53%, #73014D8C 100%);
    box-shadow: inset 0px 0px 24px #A80371;
    border: 2px solid #D4AE50;
    border-radius: 0px 20px 20px 0px;
    width: 100%;
    color: #000;
    font-size: 20px;
    font-weight: 600;
}

.fun-triple-button .common-button-main .please-img {
    width: 100%;
}

.fun-triple-button .common-button-main .last-bar-img {
    width: 70%;
}

.fun-triple-button .common-button-main  {
    position: relative;
}

.fun-triple-button .common-button-main .please-button-class {
    position: absolute;
    top: 13%;
    left: 37%;
}

.fun-triple-button .brown-bg-round {
    background: #465751;
    border: 2px solid #EDEA98;
    border-radius: 50px;
    margin-top: 1%;
    margin-bottom: 1%;
}

@media(min-width:577px) and (max-width:768px) {
  
}

@media(min-width:769px) and (max-width:992px) {
   
}

@media(min-width:992px) and (max-width:1200px) {

}

@media(min-width:1201px) and (max-width:1440px) {
    .fun-triple-button .last-number {
        font-size: 19px;
    }
    .fun-triple-button .number-class {
        font-size: 19px;
    }
    .common-button-main .first-line-button {
        font-size: 15px;
    }
    .common-button-main .single-common-button {
        top: 27%;
        left: 76%;
    }
    .common-button-main .take-common-button {
        top: 27%;
        left: 14%;
    }
    .common-button-main .bet-ok-button {
        top: 29%;
        left: 69%;
    }
    .common-button-main .sub-common-button {
        top: 26%;
    }
    .fun-triple-button .digit-round-main .digit-img {
        width: 55px;
        height: 53px;
    }
    .fun-triple-button .digit-round-main .digit-round {
        font-size: 11px;
    }
    @media(min-width:2201px) and (max-width:3000px) {
        .common-button-main .single-common-button {
            position: absolute;
            top: 21%;
            left: 85%;
        }
    }
}


