/* .fun-right-table .empty-table-block  {
    border: 1px solid #D4AE50;
} */

/* .fun-right-table .main-left-table {
    border: 3px solid #EDEA98;
} */

.fun-right-table .empty-table-block .empty-brown {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    font-size: 20px;
    padding: 0px 12px;
    color: transparent;
}

.fun-right-table .last-empty-brown {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    border: 1px solid #D4AE50;
    padding: 0px 1px 9px 7px;
    color: transparent;
}

.fun-right-table .table-block .brown-bg {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    color: #FFF;
    font-size: 20px;
    padding: 7px 12px;
}

.fun-right-table .table-block .yellow-bg {
    background: transparent linear-gradient(180deg, #F8D70C 0%, #F0E56F 50%, #FDD60C 100%);
    color: #000;
    font-size: 20px;
    padding: 7px 12px;
}

.fun-right-table .table-block .brown-bg-tringle {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    padding: 0px 8px 10px 7px;
    border: 1px solid #D4AE50;
}



.fun-right-table .table-block .right-brown-bg-tringle {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    padding: 0px 10px 0px 0px;
    border: 1px solid #D4AE50;
}


.fun-right-table .arrow-down {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #DBEB05;
}

.fun-right-table .arrow-right {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #DBEB05;
}

/* Left Table */

.fun-right-table .arrow-left {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #DBEB05;
}

.fun-right-table .table-block .left-brown-bg-tringle {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    border: 1px solid #D4AE50;
    padding: 0px 0px 0px 10px;
}

.fun-right-table .left-empty-table-block .empty-brown {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    border-right: 1px solid #D4AE50;
    font-size: 19px;
    padding: 0px 12px;
    color: #FFF;
}

.fun-right-table .table-block .down-brown-bg-tringle {
    background: transparent linear-gradient(180deg, #282A12 0%, #4F4E2C 50%, #282A12 100%);
    padding: 0px 13px 10px 12px;
    border: 1px solid #D4AE50;
}

.fun-right-table .right-triple-table .d-flex {
    justify-content: end;
}

/* .fun-right-table .common-button-main .double-bg-img {
    position: absolute;
    top: 0%;
    left: 12%;
} */

@media(min-width:577px) and (max-width:768px) {}

@media(min-width:769px) and (max-width:992px) {}

@media(min-width:992px) and (max-width:1200px) {}

@media(min-width:1201px) and (max-width:1440px) {
    .fun-right-table .left-empty-table-block .empty-brown {
        font-size: 15px;
        padding: 0px 11px;
    }

    .fun-triple-text .fun-triple-title h5 {
        font-size: 57px;
    }

    .fun-triple-text .first-line-button-main .first-line-button-text .first-line-button {
        font-size: 17px;
    }

    .fun-triple-text .score-text {
        font-size: 17px;
    }

    .fun-right-table .table-block .yellow-bg {
        font-size: 15px;
    }

    .fun-right-table .table-block .brown-bg {
        font-size: 15px;
        padding: 8px 11px;
    }

    .fun-right-table .table-block .brown-bg-tringle {
        padding: 0px 3px 0px 4px;
    }

    .fun-right-table .empty-table-block .empty-brown {
        font-size: 21px;
        padding: 0px 8px;
    }

    .fun-right-table .table-block .down-brown-bg-tringle {
        padding: 0px 7px 10px 8px;
    }
}