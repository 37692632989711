.main-triple-fun {
    background-image: url('../../../../public//assets/Image/tripal-fun/Background.png');
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0%;
}

.main-triple-fun .triple-fun-game {
    position: absolute;
    top: 27%;
    left: 39%;
}

@media(min-width:486px) and (max-width:576px) {
   
}

@media(min-width:577px) and (max-width:768px) {
  
}

@media(min-width:769px) and (max-width:992px) {
   
}

@media(min-width:992px) and (max-width:1200px) {

}

@media(min-width:1201px) and (max-width:1440px) {
    .main-triple-fun .triple-fun-game {
        position: absolute;
        top: 26%;
        left: 32%;
    }
}

@media(min-width:2201px) and (max-width:3000px) {
    .main-triple-fun .triple-fun-game {
        position: absolute;
        top: 27%;
        left: 41%;
    }
    
}