

.fun-main .funmain-bg {
    position: relative;
}

.fun-main .funmain-bg .fun-sub-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

 .fun-main .funmain-bg .fun-sub-bg .funbg-img  {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;  
}

.fun-main .funmain-bg .fun-text-holder-main {
    position: absolute;
    top: 0;
}