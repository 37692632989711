.score-winner .cards-img {
    margin-top: 30%;
}

.score-winner .score-text {
    font-size: 25px;
    font-weight: 400;
    color: #FFF;
    line-height: 2px;
}

.score-winner .score-button-main {
    position: relative;
}

.score-winner .score-button-main .score-number {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    top: 18%;
    left: 58%;
}

.score-winner .score-button-main .score-button .score-btn-class {
    background: transparent;
    border: none;
}

.score-winner .score-button-main .score-button .score-btn-class .score-img {
    width: 200px;
}

.score-winner .score-button-main .left-bg-glow {
    position: absolute;
    width: 25px;
    top: 63%;
    left: 0%;
}

.score-winner .score-button-main .right-bg-glow {
    position: absolute;
    width: 25px;
    top: 63%;
    right: 0%;
}

/* second row */

.score-winner .main-class-title {
    position: relative;
}

.score-winner .a-to-6 {
    position: absolute;
    top: 23%;
    left: 16%;
}

.score-winner .a-to-6 p {
    color: #FFF;
    font-size: 30px;
}

.score-winner .seven-text {
    position: absolute;
    top: 23%;
    left: 51%;
}

.score-winner .seven-text p {
    color: #FFF;
    font-size: 30px;
}

.score-winner .eight-to-k {
    position: absolute;
    top: 23%;
    left: 78%;
}

.score-winner .eight-to-k p {
    color: #FFF;
    font-size: 30px;
}

.score-winner .main-class-title .love-img-main {
    position: absolute;
    top: 8%;
    left: 9%;
}

.score-winner .main-class-title .love-img-main .love-img {
    width: 100px;
    height: 80px;
}

.score-winner .main-class-title .love-img-square {
    position: absolute;
    top: 8%;
    left: 32%;
}

.score-winner .main-class-title .love-img-square .love-img {
    width: 100px;
    height: 80px;
}

.score-winner .main-class-title .love-img-threecard {
    position: absolute;
    top: 8%;
    left: 57%;
}

.score-winner .main-class-title .love-img-threecard .love-img {
    width: 100px;
    height: 80px;
}

.score-winner .main-class-title .love-img-badam {
    position: absolute;
    top: 8%;
    left: 81%;
}

.score-winner .main-class-title .love-img-badam .love-img {
    width: 100px;
    height: 80px;
}

.score-winner .main-class-title .red-img-card {
    position: absolute;
    top: 4%;
    left: 19%;
}

.score-winner .main-class-title .red-img-card .red-img {
    width: 100px;
    height: 80px;
}

.score-winner .main-class-title .black-img-card {
    position: absolute;
    top: 4%;
    left: 48%;
}

.score-winner .main-class-title .black-img-card .black-img {
    width: 100px;
    height: 80px;
}

.score-winner .title-img {
    width: 100%;
    height: 90px;
}

.score-winner .first-card-img {
    width: 50%;
}

.score-winner .title-middle-img {
    width: 70%;
    width: 66px;
    height: 66px;
    background: #0044AF 0% 0% no-repeat padding-box;
    border: 2px solid #073378;
}

.score-winner .andhar-text {
    width: 66px;
    height: 66px;
    font-size: 20px;
    font-weight: 600;
    padding: 16px 0px 8px 0px;
    background: #0044AF;
    border: 2px solid #073378;
    border-radius: 100%;
    color: #FFF;
    margin: auto;
}

/* .score-winner .cards-img-second {
    width: 186px;
} */

.score-winner .bg-score {
    position: relative;
}

.score-winner .bg-score .first-card-img-main {
    position: absolute;
    top: 2%;
}

.score-winner .bg-score .right-bg-card-img {
    width: 75%;
}

.score-winner .bg-score .center-card-img-main {
    position: absolute;
    top: 7%;
}

.score-winner .bg-score .first-card-center-img {
    width: 52%;
}

/* Media Query */
@media(min-width:577px) and (max-width:768px) {
    .score-winner .score-button-main .left-bg-glow {
        width: 18px;
        top: 43%;
    }

    .score-winner .score-button-main .right-bg-glow {
        width: 18px;
        top: 43%;
    }

    .score-winner .score-text {
        font-size: 15px;
        line-height: 0px;
    }

    .score-winner .score-button-main .score-button .score-btn-class .score-img {
        width: 107px;
    }

    .score-winner .score-button-main .score-number {
        font-size: 12px;
    }

    .score-winner .cards-img {
        margin-top: 0%;
        width: 50px;
    }

    .main-fun-darbhar .women-img-main .women-img {
        width: 180px;
        height: 180px;
    }

    .score-winner .title-img {
        height: 53px;
    }

    .score-winner .main-class-title .love-img-main .love-img {
        width: 70px;
        height: 72px;
    }

    .score-winner .main-class-title .love-img-main {
        top: -9px;
        left: 3%;
    }

    .score-winner .main-class-title .love-img-square {
        top: -7px;
        left: 25%;
    }

    .score-winner .main-class-title .love-img-square .love-img {
        width: 80px;
        height: 68px;
    }

    .score-winner .main-class-title .love-img-threecard .love-img {
        width: 70px;
        height: 70px;
    }

    .score-winner .main-class-title .love-img-threecard {
        top: -16%;
        left: 51%;
    }

    .score-winner .main-class-title .love-img-badam .love-img {
        width: 70px;
        height: 70px;
    }

    .score-winner .main-class-title .love-img-badam {
        position: absolute;
        top: -16%;
        left: 74%;
    }

    .score-winner .main-class-title .black-img-card .black-img {
        width: 60px;
        height: 60px;
    }

    .score-winner .main-class-title .black-img-card {
        top: 0%;
        left: 44%;
    }

    .score-winner .main-class-title .red-img-card .red-img {
        width: 60px;
        height: 60px;
    }

    .score-winner .main-class-title .red-img-card {
        position: absolute;
        top: 0;
        left: -5%;
    }

    .score-winner .a-to-6 p {
        font-size: 16px;
    }

    .score-winner .seven-text p {
        font-size: 16px;
    }

    .score-winner .eight-to-k p {
        font-size: 16px;
    }

    .score-winner .andhar-text {
        width: 38px;
        height: 38px;
        font-size: 10px;
        padding: 9px 0px 8px 0px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .score-winner .score-button-main .left-bg-glow {
        width: 18px;
        top: 43%;
    }

    .score-winner .score-button-main .right-bg-glow {
        width: 18px;
        top: 43%;
    }

    .score-winner .score-text {
        font-size: 15px;
        line-height: 0px;
    }

    .score-winner .score-button-main .score-button .score-btn-class .score-img {
        width: 107px;
    }

    .score-winner .score-button-main .score-number {
        font-size: 12px;
    }

    .score-winner .cards-img {
        margin-top: 0%;
        width: 50px;
    }

    .main-fun-darbhar .women-img-main .women-img {
        width: 180px;
        height: 180px;
    }

    .score-winner .title-img {
        height: 53px;
    }

    .score-winner .main-class-title .love-img-main .love-img {
        width: 70px;
        height: 72px;
    }

    .score-winner .main-class-title .love-img-main {
        top: -9px;
        left: 3%;
    }

    .score-winner .main-class-title .love-img-square {
        top: -7px;
        left: 25%;
    }

    .score-winner .main-class-title .love-img-square .love-img {
        width: 80px;
        height: 68px;
    }

    .score-winner .main-class-title .love-img-threecard .love-img {
        width: 70px;
        height: 70px;
    }

    .score-winner .main-class-title .love-img-threecard {
        top: -16%;
        left: 51%;
    }

    .score-winner .main-class-title .love-img-badam .love-img {
        width: 70px;
        height: 70px;
    }

    .score-winner .main-class-title .love-img-badam {
        position: absolute;
        top: -16%;
        left: 74%;
    }

    .score-winner .main-class-title .black-img-card .black-img {
        width: 60px;
        height: 60px;
    }

    .score-winner .main-class-title .black-img-card {
        top: 0%;
        left: 44%;
    }

    .score-winner .main-class-title .red-img-card .red-img {
        width: 60px;
        height: 60px;
    }

    .score-winner .main-class-title .red-img-card {
        position: absolute;
        top: 0;
        left: -5%;
    }

    .score-winner .a-to-6 p {
        font-size: 16px;
    }

    .score-winner .seven-text p {
        font-size: 16px;
    }

    .score-winner .eight-to-k p {
        font-size: 16px;
    }

    .score-winner .andhar-text {
        width: 38px;
        height: 38px;
        font-size: 10px;
        padding: 9px 0px 8px 0px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .score-winner .score-button-main .left-bg-glow {
        width: 18px;
        top: 43%;
    }

    .score-winner .score-button-main .right-bg-glow {
        width: 18px;
        top: 43%;
    }

    .score-winner .score-text {
        font-size: 15px;
        line-height: 0px;
    }

    .score-winner .score-button-main .score-button .score-btn-class .score-img {
        width: 107px;
    }

    .score-winner .score-button-main .score-number {
        font-size: 12px;
    }

    .score-winner .cards-img {
        margin-top: 0%;
        width: 50px;
    }

    .score-winner .title-img {
        height: 53px;
    }

    .score-winner .main-class-title .love-img-main .love-img {
        width: 70px;
        height: 72px;
    }

    .score-winner .main-class-title .love-img-main {
        top: -9px;
        left: 3%;
    }

    .score-winner .main-class-title .love-img-square {
        top: -7px;
        left: 25%;
    }

    .score-winner .main-class-title .love-img-square .love-img {
        width: 80px;
        height: 68px;
    }

    .score-winner .main-class-title .love-img-threecard .love-img {
        width: 70px;
        height: 70px;
    }

    .score-winner .main-class-title .love-img-threecard {
        top: -16%;
        left: 51%;
    }

    .score-winner .main-class-title .love-img-badam .love-img {
        width: 70px;
        height: 70px;
    }

    .score-winner .main-class-title .love-img-badam {
        position: absolute;
        top: -16%;
        left: 74%;
    }

    .score-winner .main-class-title .black-img-card .black-img {
        width: 60px;
        height: 60px;
    }

    .score-winner .main-class-title .black-img-card {
        top: 0%;
        left: 44%;
    }

    .score-winner .main-class-title .red-img-card .red-img {
        width: 60px;
        height: 60px;
    }

    .score-winner .main-class-title .red-img-card {
        position: absolute;
        top: -4px;
        left: 7%;
    }

    .score-winner .a-to-6 p {
        font-size: 16px;
    }

    .score-winner .seven-text p {
        font-size: 16px;
    }

    .score-winner .eight-to-k p {
        font-size: 16px;
    }

    .score-winner .andhar-text {
        width: 38px;
        height: 38px;
        font-size: 10px;
        padding: 9px 0px 8px 0px;
    }
}

@media(min-width:1201px) and (max-width:1440px) {
    .score-winner .score-text {
        font-size: 18px;
        line-height: 0px;
    }

    .score-winner .score-button-main .score-button .score-btn-class .score-img {
        width: 150px;
    }

    .score-winner .score-button-main .score-number {
        font-size: 15px;
    }

    .score-winner .cards-img {
        margin-top: 0%;
        width: 90px;
    }

    .main-fun-darbhar .women-img-main .women-img {
        width: 250px;
        height: 250px;
    }

    .score-winner .title-img {
        height: 65px;
    }

    .score-winner .main-class-title .love-img-main .love-img {
        width: 80px;
        height: 80px;
    }

    .score-winner .main-class-title .love-img-main {
        top: -6px;
        left: 8%;
    }

    .score-winner .main-class-title .love-img-square {
        top: 0px;
        left: 31%;
    }

    .score-winner .main-class-title .love-img-square .love-img {
        width: 80px;
        height: 68px;
    }

    .score-winner .main-class-title .love-img-threecard .love-img {
        width: 70px;
        height: 70px;
    }

    .score-winner .main-class-title .love-img-threecard {
        top: -7%;
        left: 57%;
    }

    .score-winner .main-class-title .love-img-badam .love-img {
        width: 70px;
        height: 70px;
    }

    .score-winner .main-class-title .love-img-badam {
        position: absolute;
        top: -7%;
        left: 81%;
    }

    .score-winner .main-class-title .black-img-card .black-img {
        width: 60px;
        height: 60px;
    }

    .score-winner .main-class-title .black-img-card {
        top: 0%;
        left: 58%;
    }

    .score-winner .main-class-title .red-img-card .red-img {
        width: 60px;
        height: 60px;
    }

    .score-winner .main-class-title .red-img-card {
        top: 0;
        left: 12%;
    }

    .score-winner .a-to-6 p {
        font-size: 18px;
    }

    .score-winner .seven-text p {
        font-size: 18px;
    }

    .score-winner .eight-to-k p {
        font-size: 18px;
    }

    .score-winner .andhar-text {
        width: 38px;
        height: 38px;
        font-size: 10px;
        padding: 9px 0px 8px 0px;
    }
}