@font-face {
    font-family: Gamer Station Extrude;
    src: url('../../../../public/assets/Image/Gamer\ Station\ Extrude.ttf');
}

.fun-target-text {
    background-image: url('../../../../public/assets/Image/fun-target/fun-target-bg.png');
    width: 100%;
    /* height: 1085px; */
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0%;
}

.fun-target-text .text-bg-img {
    width: 281px;
    height: 50px;
}

.fun-target-text .fun-target-img {
    width: 70%;
    position: relative;
}

.fun-target-text .text-bg-main {
    position: relative;
}

.fun-target-text .text-bg-main .number-text {
    position: absolute;
    top: 17%;
    left: 65%;
}

.fun-target-text .text-bg-main .number-text P {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    /* font-family: 'Gamer Station Extrude'; */
}

.fun-target-text .text-bg-main .number-text-seris {
    position: absolute;
    top: 22%;
    left: 32%;
}

.fun-target-text .text-bg-main .number-text-seris P {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.fun-target-text .text-target {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    line-height: 2px;
    margin-top: 20%;
    font-family: 'Gamer Station Extrude';
}


.fun-target-text .brown-bg {
    background: transparent linear-gradient(262deg, #D5A340 0%, #FCF089 51%, #B98434 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 50px 50px 0px;
    margin: 35% 0% 0% 0%;
}

.fun-target-text .brown-bg .round-img {
    width: 100%;
}

.fun-target-text .brown-bg-right {
    background: transparent linear-gradient(262deg, #D5A340 0%, #FCF089 51%, #B98434 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px 0px 0px 50px;
    margin: 35% 0% 0% 0%;
}

.fun-target-text .brown-bg-right .round-main {
    position: relative;
}

.fun-target-text .brown-bg-right .round-main .text-round {
    position: absolute;
    top: 0;
    left: 20%;
}

.fun-target-text .brown-bg-right .round-main .text-round p {
    color: #FFF;
    font-size: 30px;
    font-weight: 500;
    /* color: #4D4D4E80; */
}

.fun-target-text .brown-bg-right .round-img {
    width: 100%;
}

.fun-target-text .brown-bg .round-main {
    position: relative;
}

.fun-target-text .brown-bg .round-main .text-round {
    position: absolute;
    top: 0;
    left: 40%;
}

.fun-target-text .brown-bg .round-main .text-round p {
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
}

.fun-target-text .yellow-main {
    position: absolute;
    top: 51%;
    left: 0;
    right: 0;
}

.fun-target-text .yellow-main .yellow-strip {
    position: relative;
}

.fun-target-text .yellow-strip .yellow-strip-img {
    width: 100%;
}

.fun-target-text .yellow-strip .title-fun-target {
    position: absolute;
    top: 35%;
    left: 35%;
}

.fun-target-text .yellow-strip .dimond-main-first {
    position: absolute;
    top: 54px;
    left: 92px;
}

.fun-target-text .yellow-strip .dimond-main-first .dimond-img {
    width: 32px;
    height: 32px;
}

.fun-target-text .yellow-strip .dimond-main-second {
    position: absolute;
    top: 72px;
    left: 268px;
}

.fun-target-text .yellow-strip .dimond-main-second .dimond-img {
    width: 32px;
    height: 32px;
}

.fun-target-text .yellow-strip .dimond-main-third {
    position: absolute;
    top: 86px;
    left: 444px;
}

.fun-target-text .yellow-strip .dimond-main-third .dimond-img {
    width: 32px;
    height: 32px;
}

.fun-target-text .yellow-strip .dimond-main-four {
    position: absolute;
    top: 100px;
    left: 620px;
}

.fun-target-text .yellow-strip .dimond-main-four .dimond-img {
    width: 32px;
    height: 32px;
}

/* right side */

.fun-target-text .yellow-strip .dimond-main-five {
    position: absolute;
    top: 75px;
    right: 94px;
}

.fun-target-text .yellow-strip .dimond-main-five .dimond-img {
    width: 32px;
    height: 32px;
}

.fun-target-text .yellow-strip .dimond-main-six {
    position: absolute;
    top: 84px;
    right: 270px;
}

.fun-target-text .yellow-strip .dimond-main-six .dimond-img {
    width: 32px;
    height: 32px;
}

.fun-target-text .yellow-strip .dimond-main-seven {
    position: absolute;
    top: 95px;
    right: 446px;
}

.fun-target-text .yellow-strip .dimond-main-seven .dimond-img {
    width: 32px;
    height: 32px;
}

.fun-target-text .yellow-strip .dimond-main-eight {
    position: absolute;
    top: 106px;
    right: 621px;
}

.fun-target-text .yellow-strip .dimond-main-eight .dimond-img {
    width: 32px;
    height: 32px;
}

.fun-target-text .yellow-strip .title-fun-target .title-img {
    width: 600px;
}

.fun-target-text .take-btn {
    border-right: 6px solid #C9963C;
    border-top: 2px solid #C9963C;
    border-bottom: 2px solid #C9963C;
    background: transparent linear-gradient(90deg, #BA4319 0%, #942735 52%, #730C1D 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 50px 50px 0px;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    text-align: start;
    margin-top: 5%;
    font-family: 'Gamer Station Extrude';
    cursor: pointer;
}

.fun-target-text .cancel-btn {
    border-right: 6px solid #C9963C;
    border-left: 6px solid #C9963C;
    border-top: 2px solid #C9963C;
    border-bottom: 2px solid #C9963C;
    background: transparent linear-gradient(90deg, #BA4319 0%, #942735 52%, #730C1D 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    text-align: start;
    margin-top: 5%;
    font-family: 'Gamer Station Extrude';
    cursor: pointer;
}

.fun-target-text .bet-btn {
    border-left: 5px solid #C9963C;
    border-top: 2px solid #C9963C;
    border-bottom: 2px solid #C9963C;
    background: transparent linear-gradient(90deg, #BA4319 0%, #942735 52%, #730C1D 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px 0px 0px 50px;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    text-align: start;
    margin-top: 5%;
    font-family: 'Gamer Station Extrude';
    cursor: pointer;
}

.fun-target-text .input-group input {
    background: transparent;
    border: none;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    border-radius: 0px;
}

.fun-target-text .input-group {
    width: 100%;
    background-size: cover;
    height: 60px;
    background-image: url('/public/assets/Image/fun-target/empty-bg.png');
    padding: 15px 20px;
    border-radius: 0px;
}

.fun-target-text .red-round {
    position: relative;
    margin-top: 25%;
}

.fun-target-text .red-round .red-img {
    width: 75px;
}

.fun-target-text .red-round .empty-bg-img {
    width: 100%;
}

.fun-target-text .red-round .one {
    position: absolute;
    top: 54%;
    left: 44%;
}

.fun-target-text .red-round .one p {
    color: #FFF;
    font-weight: 600;
    font-size: 32px;
}

.fun-target-text .empty-btn {
    background: transparent linear-gradient(270deg, #681300 0%, #D93838 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 50px 50px 0px;
    font-size: 20px;
    color: #000;
    border-top: 3px solid #C9963C;
    border-bottom: 3px solid #C9963C;
    border-right: 10px solid #C9963C;
    font-weight: 600;
    font-family: 'Gamer Station Extrude';
}

.fun-target-text .yellow-game-bg {
    border-top: 5px solid #C9963C;
    border-left: 15px solid #C9963C;
    border-right: 15px solid #C9963C;
    border-radius: 50px 50px 50px 50px;
}

.fun-target-text .yellow-game-bg .game-over-btn {
    width: 100%;
    background: #FFFFFF;
    border-radius: 0px;
    font-size: 20px;
    color: #000;
    border: none;
    font-family: 'Gamer Station Extrude';
}

.fun-target-text .exit-btn {
    background: transparent linear-gradient(90deg, #ECB633 0%, #B18214 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px 0px 0px 50px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    border-top: 3px solid #C9963C;
    border-bottom: 3px solid #C9963C;
    border-left: 10px solid #C9963C;
    font-family: 'Gamer Station Extrude';
}

/* Glow CSS */


.title-dimond-glow .triple-bg-glow {
    position: absolute;
    top: 0;
    left: 12%;
    height: 30px;
    width: 60px;
}

@media(min-width:577px) and (max-width:768px) {
    .fun-target-text .red-round .one {
        top: 47%;
    }

    .fun-target-text .input-group {
        height: 17px;
        padding: 5px 9px;
    }

    .fun-target-text .red-round .red-img {
        width: 44px;
    }

    .fun-target-text .brown-bg .round-main .text-round p {
        font-size: 14px;
    }

    .fun-target-text .brown-bg-right .round-main .text-round p {
        font-size: 11px;
    }

    .fun-target-text .text-target {
        font-size: 16px;
    }

    .fun-target-text .yellow-main {
        top: 23%;
    }

    .fun-target-text .yellow-strip .dimond-main-first .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-first {
        top: 35px;
        left: 62px;
    }

    .fun-target-text .yellow-strip .dimond-main-second .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-second {
        top: 46px;
        left: 181px;
    }

    .fun-target-text .yellow-strip .dimond-main-third .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-third {
        top: 56px;
        left: 300px;
    }

    .fun-target-text .yellow-strip .dimond-main-four .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .text-bg-main .number-text-seris {
        top: 22%;
        left: 13%;
    }

    .fun-target-text .yellow-strip .title-fun-target .title-img {
        width: 238px;
    }

    .fun-target-text .brown-bg {
        margin: 8% 0% 0% 0%;
    }

    .fun-target-text .brown-bg-right {
        margin: 8% 0% 0% 0%;
    }

    .fun-target-text .yellow-strip .dimond-main-four {
        top: 66px;
        left: 421px;
    }

    .fun-target-text .yellow-strip .dimond-main-five .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-five {
        top: 48px;
        right: 62px;
    }

    .fun-target-text .yellow-strip .dimond-main-six .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-six {
        top: 56px;
        right: 182px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven {
        top: 64px;
        right: 301px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight {
        top: 71px;
        right: 422px;
    }

    .fun-target-text .red-round .one p {
        font-size: 12px;
    }

    .fun-target-text .empty-btn {
        font-size: 15px;
    }

    .fun-target-text .bet-btn {
        font-size: 15px;
    }

    .fun-target-text .take-btn {
        font-size: 15px;
    }

    .fun-target-text .cancel-btn {
        font-size: 15px;

    }

    .fun-target-text .exit-btn {
        font-size: 15px;
    }

    .fun-target-text .yellow-game-bg .game-over-btn {
        font-size: 15px;

    }

    .fun-target-text {
        height: 100%;
    }

    .fun-target-text .brown-bg .round-main .text-round {
        top: 0;
        left: 39%;
    }

    .fun-target-text .brown-bg .round-img {
        width: 32px;
    }

    .fun-target-text .text-bg-img {
        width: 153px;
        height: 32px;
    }

    .fun-target-text .text-bg-main .number-text P {
        font-size: 14px;
    }

    .fun-target-text .text-bg-main .number-text-seris P {
        font-size: 10px;
    }

    .fun-target-text .brown-bg-right .round-img {
        width: 32px;
    }

    .fun-target-text .brown-bg-right .round-main .text-round {
        left: 42%;
        top: 5px;
    }

}

@media(min-width:769px) and (max-width:992px) {
    .fun-target-text .red-round .red-img {
        width: 44px;
    }

    .fun-target-text .brown-bg .round-main .text-round p {
        font-size: 14px;
    }

    .fun-target-text .brown-bg-right .round-main .text-round p {
        font-size: 14px;
    }

    .fun-target-text .text-target {
        font-size: 16px;
    }

    .fun-target-text .yellow-main {
        top: 27%;
    }

    .fun-target-text .yellow-strip .dimond-main-first .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-first {
        top: 35px;
        left: 62px;
    }

    .fun-target-text .yellow-strip .dimond-main-second .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-second {
        top: 46px;
        left: 181px;
    }

    .fun-target-text .yellow-strip .dimond-main-third .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-third {
        top: 56px;
        left: 300px;
    }

    .fun-target-text .yellow-strip .dimond-main-four .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .text-bg-main .number-text-seris {
        top: 22%;
        left: 13%;
    }

    .fun-target-text .yellow-strip .title-fun-target .title-img {
        width: 238px;
    }

    .fun-target-text .brown-bg {
        margin: 8% 0% 0% 0%;
    }

    .fun-target-text .brown-bg-right {
        margin: 8% 0% 0% 0%;
    }

    .fun-target-text .yellow-strip .dimond-main-four {
        top: 66px;
        left: 421px;
    }

    .fun-target-text .yellow-strip .dimond-main-five .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-five {
        top: 48px;
        right: 62px;
    }

    .fun-target-text .yellow-strip .dimond-main-six .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-six {
        top: 56px;
        right: 182px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven {
        top: 64px;
        right: 301px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight {
        top: 71px;
        right: 422px;
    }

    .fun-target-text .red-round .one p {
        font-size: 12px;
    }

    .fun-target-text .empty-btn {
        font-size: 15px;
    }

    .fun-target-text .bet-btn {
        font-size: 15px;
    }

    .fun-target-text .take-btn {
        font-size: 15px;
    }

    .fun-target-text .cancel-btn {
        font-size: 15px;

    }

    .fun-target-text .exit-btn {
        font-size: 15px;
    }

    .fun-target-text .yellow-game-bg .game-over-btn {
        font-size: 15px;

    }

    .fun-target-text {
        /* height: 466px; */
        height: 100%;
    }

    .fun-target-text .brown-bg .round-main .text-round {
        top: 0;
        left: 72%;
    }

    .fun-target-text .brown-bg .round-img {
        width: 46px;
    }

    .fun-target-text .text-bg-img {
        width: 153px;
        height: 32px;
    }

    .fun-target-text .text-bg-main .number-text P {
        font-size: 14px;
    }

    .fun-target-text .text-bg-main .number-text-seris P {
        font-size: 10px;
    }

    .fun-target-text .brown-bg-right .round-img {
        width: 48px;
    }

    .fun-target-text .brown-bg-right .round-main .text-round {
        left: 42%;
        top: 2px;
    }

}

@media(min-width:993px) and (max-width:1200px) {
    .fun-target-text .input-group {
        height: 12px;
        padding: 12px 20px;
    }

    .fun-target-text .red-round .red-img {
        width: 44px;
    }

    .fun-target-text .brown-bg .round-main .text-round p {
        font-size: 14px;
    }

    .fun-target-text .brown-bg-right .round-main .text-round p {
        font-size: 14px;
    }

    .fun-target-text .text-target {
        font-size: 16px;
    }

    .fun-target-text .yellow-main {
        top: 32%;
    }

    .fun-target-text .yellow-strip .dimond-main-first .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-first {
        top: 35px;
        left: 62px;
    }

    .fun-target-text .yellow-strip .dimond-main-second .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-second {
        top: 46px;
        left: 181px;
    }

    .fun-target-text .yellow-strip .dimond-main-third .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-third {
        top: 56px;
        left: 300px;
    }

    .fun-target-text .yellow-strip .dimond-main-four .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .text-bg-main .number-text-seris {
        top: 25%;
        left: 24%;
    }

    .fun-target-text .yellow-strip .title-fun-target .title-img {
        width: 238px;
    }

    .fun-target-text .brown-bg {
        margin: 8% 0% 0% 0%;
    }

    .fun-target-text .brown-bg-right {
        margin: 8% 0% 0% 0%;
    }

    .fun-target-text .yellow-strip .dimond-main-four {
        top: 66px;
        left: 421px;
    }

    .fun-target-text .yellow-strip .dimond-main-five .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-five {
        top: 48px;
        right: 62px;
    }

    .fun-target-text .yellow-strip .dimond-main-six .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-six {
        top: 56px;
        right: 182px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven {
        top: 64px;
        right: 301px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight .dimond-img {
        width: 13px;
        height: 13px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight {
        top: 71px;
        right: 422px;
    }

    .fun-target-text .red-round .one p {
        font-size: 12px;
    }

    .fun-target-text .empty-btn {
        font-size: 15px;
    }

    .fun-target-text .bet-btn {
        font-size: 15px;
    }

    .fun-target-text .take-btn {
        font-size: 15px;
    }

    .fun-target-text .cancel-btn {
        font-size: 15px;

    }

    .fun-target-text .exit-btn {
        font-size: 15px;
    }

    .fun-target-text .yellow-game-bg .game-over-btn {
        font-size: 15px;

    }

    .fun-target-text {
        height: 100%;
    }

    .fun-target-text .brown-bg .round-main .text-round {
        top: 0;
        left: 41%;
    }

    .fun-target-text .brown-bg .round-img {
        width: 46px;
    }

    .fun-target-text .text-bg-img {
        width: 153px;
        height: 32px;
    }

    .fun-target-text .text-bg-main .number-text P {
        font-size: 14px;
    }

    .fun-target-text .text-bg-main .number-text-seris P {
        font-size: 10px;
    }

    .fun-target-text .brown-bg-right .round-img {
        width: 48px;
    }

    .fun-target-text .brown-bg-right .round-main .text-round {
        left: 42%;
        top: 2px;
    }

}

@media(min-width:1201px) and (max-width:1440px) {
    .fun-target-text .input-group {
        height: 40px;
    }

    .fun-target-text .brown-bg .round-main .text-round p {
        font-size: 16px;
    }

    .fun-target-text .brown-bg-right .round-main .text-round p {
        font-size: 17px;
    }

    .fun-target-text .text-target {
        font-size: 20px;
    }

    .fun-target-text .yellow-main {
        top: 56%;
    }

    .fun-target-text .yellow-strip .dimond-main-first .dimond-img {
        width: 25px;
        height: 25px;
    }

    .fun-target-text .yellow-strip .dimond-main-first {
        top: 35px;
        left: 62px;
    }

    .fun-target-text .yellow-strip .dimond-main-second .dimond-img {
        width: 25px;
        height: 25px;
    }

    .fun-target-text .yellow-strip .dimond-main-second {
        top: 46px;
        left: 181px;
    }

    .fun-target-text .yellow-strip .dimond-main-third .dimond-img {
        width: 25px;
        height: 25px;
    }

    .fun-target-text .yellow-strip .dimond-main-third {
        top: 56px;
        left: 300px;
    }

    .fun-target-text .yellow-strip .dimond-main-four .dimond-img {
        width: 25px;
        height: 25px;
    }

    .fun-target-text .text-bg-main .number-text-seris {
        top: 22%;
        left: 22%;
    }

    .fun-target-text .yellow-strip .title-fun-target .title-img {
        width: 419px;
    }

    .fun-target-text .brown-bg {
        margin: 22% 0% 0% 0%;
    }

    .fun-target-text .brown-bg-right {
        margin: 22% 0% 0% 0%;
    }

    .fun-target-text .yellow-strip .dimond-main-four {
        top: 66px;
        left: 421px;
    }

    .fun-target-text .yellow-strip .dimond-main-five .dimond-img {
        width: 25px;
        height: 25px;
    }

    .fun-target-text .yellow-strip .dimond-main-five {
        top: 48px;
        right: 62px;
    }

    .fun-target-text .yellow-strip .dimond-main-six .dimond-img {
        width: 25px;
        height: 25px;
    }

    .fun-target-text .yellow-strip .dimond-main-six {
        top: 56px;
        right: 182px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven .dimond-img {
        width: 25px;
        height: 25px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven {
        position: absolute;
        top: 64px;
        right: 301px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight .dimond-img {
        width: 25px;
        height: 25px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight {
        position: absolute;
        top: 71px;
        right: 422px;
    }

    .fun-target-text .red-round .one p {
        color: #FFF;
        font-weight: 600;
        font-size: 20px;
    }

    .fun-target-text .empty-btn {
        font-size: 18px;
    }

    .fun-target-text .bet-btn {
        font-size: 18px;
    }

    .fun-target-text .take-btn {
        font-size: 18px;
    }

    .fun-target-text .cancel-btn {
        font-size: 18px;

    }

    .fun-target-text .exit-btn {
        font-size: 18px;
    }

    .fun-target-text .yellow-game-bg .game-over-btn {
        font-size: 18px;
    }

    .fun-target-text {
        /* height: 792px; */
        height: 100%;
    }

    .fun-target-text .red-round .one {
        top: 53%;
    }
}

@media(min-width:1441px) and (max-width:1600px) {
    .fun-target-text .brown-bg {
        margin: 19% 0% 0% 0%;
    }

    .fun-target-spinner .App-header-class .coins-main-img {
        position: absolute;
        top: 68%;
        left: 20%;
    }

    .fun-target-text .yellow-strip .title-fun-target .title-img {
        width: 400px;
    }

    .fun-target-text .brown-bg .round-main .text-round p {
        font-size: 17px;
        font-weight: 600;
    }

    .fun-target-spinner .App-header-class .coins-main-img .app-coins {
        width: 110%;
    }

    .fun-target-spinner .App-header-class .coins-main-img {
        left: 13%;
    }

    .fun-target-text .brown-bg-right {
        margin: 20% 0% 0% 0%;
    }

    .fun-target-text .yellow-main {
        top: 49%;
    }

    .fun-target-text .brown-bg-right .round-main .text-round p {
        font-size: 17px;
    }
}

@media(min-width:1601px) and (max-width:1800px) {
    .fun-target-spinner .App-header-class .coins-main-img {
        position: absolute;
        top: 68%;
        left: 20%;
    }

    .fun-target-text .yellow-strip .title-fun-target .title-img {
        width: 400px;
    }

    .fun-target-text .yellow-main {
        top: 57%;
    }
    .fun-target-text .input-group {
        height: 54px;
        padding: 11px 20px;
    }

}

@media(min-width:1801px) and (max-width:2000px) {
    .fun-target-spinner .App-header-class .coins-main-img {
        position: absolute;
        top: 68%;
        left: 27%;
    }

    .fun-target-text .yellow-strip .title-fun-target .title-img {
        width: 522px;
    }
    .fun-target-text .brown-bg {
        margin: 20% 0% 0% 0%;
    }
    .fun-target-text .brown-bg-right {
        margin: 16% 0% 0% 0%;
    }

}

@media(min-width:2201px) and (max-width:3000px) {
    .fun-target-text .yellow-main {
        top: 49%;
    }

    .fun-target-text .red-round .one {
        top: 58%;
    }

    .fun-target-text .yellow-strip .title-fun-target {
        left: 37%;
    }

    .fun-target-text .yellow-strip .dimond-main-first .dimond-img {
        width: 45px;
        height: 45px;
    }

    .fun-target-text .yellow-strip .dimond-main-second .dimond-img {
        width: 45px;
        height: 45px;
    }

    .fun-target-text .yellow-strip .dimond-main-third .dimond-img {
        width: 45px;
        height: 45px;
    }

    .fun-target-text .yellow-strip .dimond-main-four .dimond-img {
        width: 45px;
        height: 45px;
    }

    v .fun-target-text .yellow-strip .dimond-main-five .dimond-img {
        width: 45px;
        height: 45px;
    }

    .fun-target-text .yellow-strip .dimond-main-six .dimond-img {
        width: 45px;
        height: 45px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven .dimond-img {
        width: 45px;
        height: 45px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight .dimond-img {
        width: 45px;
        height: 45px;
    }

    .fun-target-text .yellow-strip .dimond-main-first {
        top: 63px;
        left: 109px;
    }

    .fun-target-text .yellow-strip .dimond-main-second {
        top: 83px;
        left: 322px;
    }

    .fun-target-text .yellow-strip .dimond-main-third {
        top: 101px;
        left: 534px;
    }

    .fun-target-text .yellow-strip .dimond-main-four {
        top: 113px;
        left: 748px;
    }

    .fun-target-text .yellow-strip .dimond-main-five {
        top: 94px;
        right: 117px;
    }

    .fun-target-text .yellow-strip .dimond-main-six {
        top: 99px;
        right: 325px;
    }

    .fun-target-text .yellow-strip .dimond-main-seven {
        top: 114px;
        right: 536px;
    }

    .fun-target-text .yellow-strip .dimond-main-eight {
        top: 127px;
        right: 750px;
    }

    .fun-target-text {
        height: 100%;
    }

    .fun-target-text .text-bg-main .number-text-seris {
        left: 35%;
    }

    .fun-target-text .text-bg-main .number-text {
        top: 17%;
        left: 62%;
    }

}