/* .game .game-img-main .game-img:hover {
    box-shadow:  #FF00008C 3px 6px 10px;
    border-radius: 20px;
    opacity: 1;
} */

.game .game-img-main {
    position: relative;
}

.game .gamed-list {
    border: 2px solid #8ecdf0;
    padding: 5px 10px;
    height: 40px;
    margin-top: 3%;
    width: 100%;
}

.game .gamed-list p {
    color: #FFF;
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Gamer Station Extrude';
}

.game .gamed-list p a:hover {
    color: #FFF;
}

.game .gamed-list .red-dot-img {
    width: 23px;
    height: 23px;
}

.game .games-title {
    position: absolute;
    top: 5%;
    left: 43%;
}

.game .games-title p {
    font-size: 30px;
    color: #FFF;
    font-family: 'Gamer Station Extrude';
}

.game .game-img-main .game-img {
    width: 100%;
}

.game .game-img-main .text-holder-game {
    position: absolute;
    top: 17%;
    left: 15%;
    width: 66%;
}

.game .game-img-main .text-holder-game input {
    background: transparent;
    width: 415px;
    height: 40px;
    margin-top: 3%;
    border: 2px solid #8ecdf0;
    cursor: pointer;
    font-family: 'Gamer Station Extrude';
}

.game .game-img-main .text-holder-game input::placeholder {
    color: #FFF;
    padding-left: 2%;
}

/* .game .game-img-main .game-name-main{
    position: relative;
} */

@media(min-width:577px) and (max-width:768px) {
    .game .games-title p {
        font-size: 17px;
    }
    .game .games-title {
        top: 8%;
        left: 38%;
    }
    .game .gamed-list p {
        font-size: 11px;
    }
    .game .gamed-list {
        padding: 0px 1px;
        height: 21px;
        margin-top: 2%;
    }
    .game .gamed-list .red-dot-img {
        width: 8px;
        height: 10px;
    }
    .game .gamed-list .red-dot-img {
        width: 8px;
        height: 8px;
    }
    .game .game-img-main .game-img {
        height: 299px;
    }    
    .game .game-img-main .text-holder-game {
        top: 22%;
        left: 10%;
    }  
}

@media(min-width:769px) and (max-width:992px) {
    .game .games-title p {
        font-size: 17px;
    }
    .game .games-title {
        top: 8%;
        left: 38%;
    }
    .game .gamed-list p {
        font-size: 11px;
    }
    .game .gamed-list {
        padding: 1px 5px;
        height: 21px;
        margin-top: 3%;
    }
    .game .gamed-list .red-dot-img {
        width: 8px;
        height: 10px;
    }
    .game .gamed-list .red-dot-img {
        width: 12px;
        height: 12px;
    }
    .game .game-img-main .game-img {
        height: 360px;
    } 
    .game .game-img-main .text-holder-game {
        top: 24%;
        left: 12%;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .game .game-img-main .text-holder-game input {
        height: 30px;
        margin-top: 2%;
        font-size: 12px;
    }
    .game .gamed-list {
        padding: 1px 10px;
        height: 22px;
        width: 184px;
    }

    .game .gamed-list p {
        font-size: 11px;
    }

    .game .gamed-list .red-dot-img {
        width: 13px;
        height: 15px;
    }

    .game .game-img-main .text-holder-game {
        top: 21%;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 47%;
        left: 32%;
    }
    .game .games-title p {
        font-size: 18px;
        color: #FFF;
    }

    .Check-authonticity .response-text {
        font-size: 18px;
    }
    .game .game-img-main .text-holder-game input {
        width: 186px;
        height: 22px;
        margin-top: 2%;
        font-size: 10px;
    }
    .game .games-title {
        top: 7%;
        left: 41%;
    }
}

@media(min-width:1201px) and (max-width:1299px) {
    .main-home {
        top: 4%;
    }

    .game .gamed-list {
        padding: 0px 10px;
        height: 24px;
    }

    .game .gamed-list p {
        font-size: 12px;
    }

    .game .gamed-list .red-dot-img {
        width: 10px;
        height: 10px;
    }

    .game .game-img-main .text-holder-game {
        top: 22%;
        left: 13%;
    }

    .home-button .logged-id .logged-id-img .log-img {
        width: 286px;
        height: 196px;
    }

    .home-button .logged-id .text-holder-points p {
        font-size: 15px;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 47%;
        left: 32%;
    }
    .game .games-title p {
        font-size: 19px;
        color: #FFF;
    }
    .game .game-img-main .text-holder-game input {
        width: 266px;
        height: 30px;
        margin-top: 2%;
        font-size: 12px;
    }
    .game .games-title {
        top: 7%;
        left: 41%;
    }  
}

@media(min-width:1300px) and (max-width:1440px) {
    .game .gamed-list {
        padding: 2px 10px;
        height: 31px;
        margin-top: 2%;
        width: 264px;
    }

    .game .gamed-list p {
        font-size: 13px;
    }

    .game .gamed-list .red-dot-img {
        width: 15px;
        height: 16px;
    }

    .game .game-img-main .text-holder-game {
        top: 14%;
    }

    .home-button .logged-id .logged-id-img .log-img {
        width: 286px;
        height: 196px;
    }

    .home-button .logged-id .text-holder-points p {
        font-size: 15px;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 47%;
        left: 32%;
    }
    .game .games-title p {
        font-size: 22px;
        color: #FFF;
    }

    .Check-authonticity .response-text {
        font-size: 18px;
    }
    .game .game-img-main .text-holder-game input {
        width: 266px;
        height: 30px;
        margin-top: 2%;
        font-size: 12px;
    }
    .game .games-title {
        top: 6%;
        left: 41%;
    }  
}

@media(min-width:1441px) and (max-width:2000px) {
    .game .gamed-list {
        padding: 2px 10px;
        height: 32px;
        margin-top: 3%;
    }

    .game .gamed-list p {
        font-size: 15px;
    }

    .game .gamed-list .red-dot-img {
        width: 15px;
        height: 16px;
    }

    .game .game-img-main .text-holder-game {
        top: 14%;
    }

    .home-button .logged-id .logged-id-img .log-img {
        width: 286px;
        height: 196px;
    }

    .home-button .logged-id .text-holder-points p {
        font-size: 15px;
    }

    .home-button .gk-point-main .text-holder-gk {
        top: 47%;
        left: 32%;
    }
    .game .games-title p {
        font-size: 28px;
    }

    .Check-authonticity .response-text {
        font-size: 18px;
    }
    .game .game-img-main .text-holder-game input {
        width: 266px;
        height: 30px;
        margin-top: 2%;
        font-size: 12px;
    }
    .game .games-title {
        top: 5%;
    } 
    .home-button .gk-point-main .text-holder-gk p {
        font-size: 12px;
    }
}

@media(min-width:2201px) and (max-width:3000px) {
    .game .gamed-list {
        border: 2px solid #8ecdf0;
        padding: 8px 10px;
        height: 50px;
        margin-top: 3%;
        /* width: 124%; */
    }
    .game .game-img-main .text-holder-game input {
        background: transparent;
        width: 510px;
        height: 50px;
        margin-top: 3%;
        border: 2px solid #8ecdf0;
        cursor: pointer;
    }
}