.fun-target-spinner {
  text-align: center;
}

.fun-target-spinner .App-logo {
  height: 57vmin;
  pointer-events: none;
}

.fun-target-spinner .App-header-class {
  position: relative;
}

.fun-target-spinner .App-header-class .coins-main-img {
  position: absolute;
  top: 68%;
  left: 29%;
}

.fun-target-spinner .App-header-class .coins-main-img .app-coins {
  /* width: 100%; */
  width: 140%;
}

.pointer-img-main {
  position: absolute;
  top: 0;
  left: 47%;
}

.pointer-img-main .pointer-img {
  width: 55px;
}

@media (prefers-reduced-motion: no-preference) {
  .fun-target-spinner .App-header-class .App-logo {
    animation: App-logo-spin infinite 1s linear;
  }
}


/* .fun-target .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  } */


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.round-center-img {
  position: absolute;
  top: 192px;
  left: 374px;
}

.round-center-img .center-img {
  width: 100%;
  /* height: 342px; */
}

@media(min-width:577px) and (max-width:768px) {
  .pointer-img-main {
    left: 47%;
  }

  .pointer-img-main .pointer-img {
    width: 20px;
  }

  .fun-target-spinner .App-logo {
    height: 29vmin;
  }

  .fun-target-spinner .App-header-class .coins-main-img .app-coins {
    width: 73%;
  }

  .fun-target-spinner .App-header-class .coins-main-img {
    top: 67%;
    left: 9%;
  }

  .pointer-img-main .pointer-img {
    width: 17px;
  }

}

@media(min-width:769px) and (max-width:992px) {
  .fun-target-spinner .App-logo {
    height: 29vmin;
  }

  .fun-target-spinner .App-header-class .coins-main-img .app-coins {
    width: 73%;
  }

  .fun-target-spinner .App-header-class .coins-main-img {
    top: 67%;
    left: 9%;
  }
}

@media(min-width:993px) and (max-width:1200px) {
  .fun-target-spinner .App-logo {
    height: 33vmin;
  }

  /* .round-center-img .center-img {
    width: 100%;
  } */

  .round-center-img {
    top: 94px;
    left: 176px;
    width: 28%;
  }

  .fun-target-spinner .App-header-class .coins-main-img .app-coins {
    width: 71%;
  }

  .fun-target-spinner .App-header-class .coins-main-img {
    top: 68%;
    left: 16%;
  }
}

@media(min-width:1201px) and (max-width:1440px) {
  /* .round-center-img .center-img {
    width: 100%;
    height: 298px;
  } */

  .round-center-img {
    top: 135px;
    left: 196px;
  }

  .fun-target-spinner .App-header-class .coins-main-img {
    position: absolute;
    top: 68%;
    left: 20%;
  }

}

@media(min-width:1441px) and (max-width:1600px) {
  .fun-target-spinner .App-logo {
    height: 50vmin;
  }

  .round-center-img {
    top: 128px;
    left: 193px;
  }

  .fun-target-spinner .App-header-class .coins-main-img {
    position: absolute;
    top: 68%;
    left: 18%;
  }
}

@media(min-width:1601px) and (max-width:1800px) {
  .round-center-img {
    top: 128px;
    left: 193px;
  }

  .fun-target-spinner .App-header-class .coins-main-img {
    position: absolute;
    top: 68%;
    left: 20%;
  }

  .fun-target-text .yellow-strip .title-fun-target .title-img {
    width: 400px;
  }

  .fun-target-text .yellow-main {
    top: 57%;
  }

}

@media(min-width:1801px) and (max-width:2000px) {

  .round-center-img {
    top: 127px;
    left: 309px;
  }

  .fun-target-spinner .App-header-class .coins-main-img {
    position: absolute;
    top: 68%;
    left: 27%;
  }

  .fun-target-text .yellow-strip .title-fun-target .title-img {
    width: 522px;
  }

  .pointer-img-main {
    position: absolute;
    top: 0;
    left: 47%;
  }

  .pointer-img-main .pointer-img {
    width: 44px;
  }

}

@media(min-width:2201px) and (max-width:3000px) {
  .round-center-img {
    position: absolute;
    top: 256px;
    left: 481px;
  }

  .fun-target-spinner .App-header-class .coins-main-img {
    position: absolute;
    top: 68%;
    left: 33%;
  }

  .fun-target-text .red-round .one {
    top: 52%;
  }
}

