.fun-game .last-btn {
    position: relative;
}

.fun-game .last-btn .img.lastline-img {
    width: 100%;
}

.fun-game .last-btn .back-btn-class {
    border: none;
    font-size: 14px;
    font-weight: 700;
    color: #FFF;
}
.fun-game .last-btn .back-btn-main {
    position: absolute;
    left: 83px;
    top: 35px;
}

.fun-game .last-btn .lastline-img {
    width: 100%;
    margin-top: 23%;
}

.fun-game .last-btn .title-roulette-img {
    width: 20%;
}

/* Second row */

/* .fun-game .second-row {
    margin-right: 3%;
    margin-left: 3%;
} */

.fun-game .second-row .last-btn {
    position: relative;
}

.fun-game .second-row .last-btn .back-btn-main {
    position: absolute;
    left: 228px;
    top: 93px;
}

.fun-game .second-row .last-btn .back-btn-main .back-btn-second-class {
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
}

.fun-game .second-row .last-btn .img.lastline-img {
    width: 100%;
}

.fun-game .second-row .last-btn .back-btn-class {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #465751;
}


.fun-game .second-row .last-btn .one-btn-main {
    position: absolute;
    left: 18px;
    top: 16px;
}

.fun-game .second-row .last-btn .second-btn-main {
    position: absolute;
    left: 18px;
    top: 16px;
}

.fun-game .second-row .last-btn .third-btn-main {
    position: absolute;
    left: 15px;
    top: 16px;
}

.fun-game .second-row .last-btn .four-btn-main {
    position: absolute;
    left: 14px;
    top: 16px;
}

.fun-game .second-row .last-btn .five-btn-main {
    position: absolute;
    left: 13px;
    top: 16px;
}

.fun-game .second-row .last-btn .six-btn-main {
    position: absolute;
    left: 11px;
    top: 16px;
}

.fun-game .second-row .last-btn .seven-btn-main {
    position: absolute;
    left: 8px;
    top: 16px;
}

.fun-game .second-row .last-btn .eight-btn-main {
    position: absolute;
    left: 6px;
    top: 16px;
}

.fun-game .second-row .last-btn .title-roulette-img {
    width: 20%;
}

.fun-game .second-row .last-btn .round-img {
    width: 80%;
    margin-top: 23%;
}

.fun-game .second-row .last-btn .white-right-btn {
    position: absolute;
    left: 0;
    top: 93px;
}

.fun-game .second-row .last-btn .white-right-btn span {
    padding: 0px 15px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
}

.fun-game .second-row .last-btn-second {
    position: relative;
}

.fun-game .second-row .last-btn-second .white-right-btn {
    position: absolute;
    top: 0;
    left: 32%;
}

.fun-game .second-row .last-btn-second .bet-ok-btn {
    position: absolute;
    top: 0;
    left: 16%;
}

.fun-game .second-row .last-btn-second  .take-btn-class {
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
}

.fun-game .second-row .last-btn-second .cancel-img {
    width: 150px;
}

.fun-game .second-row .last-btn-second .take-btn {
    width: 100%;
}

.last-btn-second .cancel-right-btn {
    position: absolute;
    top: -10px;
    left: 34%;
}

/* spinner */

.fun-game .spinner-img {
    width: 70%;
}


